<template>
  <div id="app">
    <Header />
    <main>
      <LeftNav />
      <Wall v-show="activeStep < 3" />
      <Final v-show="activeStep === 3" />
      <RightNav />
    </main>
    <Footer />
    <Init :open="initModalOpen" @close="initModalOpen = false" />
    <Error :message="errorMessage" :open="errorModalOpen" @close="closeErrorModal" />
    <TooNarrow :open="tooNarrowModalOpen" @close="tooNarrowModalOpen = false" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import Header from './components/Header.vue';
import LeftNav from './components/LeftNav.vue';
import Wall from './components/Wall.vue';
import Final from './components/Final.vue';
import RightNav from './components/RightNav.vue';
import Footer from './components/Footer.vue';
import Init from './modals/Init.vue';
import Error from './modals/Error.vue';
import TooNarrow from './modals/TooNarrow.vue';

import secureStorage from './secureStorage';

export default {
  name: 'App',
  async created() {
    const searchParams = new URLSearchParams(location.search);
    const designCode = searchParams.get('design');
    const localState = secureStorage.getState();
    const isB2B = searchParams.get('b2b') === 'true';
    document.title = isB2B ? 'Plan Your Project Tool BETA' : 'Layout Assistant BETA';
    if (designCode) {
      try {
        const res = await fetch(`${process.env.VUE_APP_SAVE_URL}/${designCode}`);
        if (res.status === 200) {
          const { data } = await res.json();
          this.initialize(data);
          if (searchParams.get('pdf') === 'true') {
            this.showPDF(designCode);
          }
        } else if (res.status === 404) {
          this.errorMessage = 'Sorry, the design code you have entered does not exist or has expired.';
          this.errorModalOpen = true;
        }
      } catch {
        this.errorMessage = 'Sorry, there was an error loading your design.';
        this.errorModalOpen = true;
      }
    } else if (localState && localState.isB2B === isB2B) {
      // without a delay, state will be initialized before Wall is mounted and thus will not be able to react to change
      setTimeout(() => {
        this.initialize(localState);
      }, 100);
    } else {
      this.initModalOpen = true;
    }
  },
  mounted() {
    const THRESHOLD = 1000;
    this.tooNarrowModalOpen = innerWidth < THRESHOLD;
    addEventListener('orientationchange', () => {
      // innerWidth does not update instantly after orientationchange event
      setTimeout(() => {
        this.tooNarrowModalOpen = innerWidth < THRESHOLD;
      }, 1000);
    });
  },
  data() {
    return {
      initModalOpen: false,
      errorMessage: '',
      errorModalOpen: false,
      tooNarrowModalOpen: false,
    };
  },
  computed: {
    ...mapState([
      'activeStep',
      'loadingFromDesignCode',
      'pdfReady',
    ]),
  },
  methods: {
    ...mapMutations([
      'initialize',
      'setStep',
    ]),
    closeErrorModal() {
      this.errorModalOpen = false;
      this.initModalOpen = true;
    },
    showPDF(designCode) {
      function waitUntilDoneLoading() {
        if (!this.loadingFromDesignCode) {
          this.setStep(3);
          waitUntilPdfReady.call(this);
        } else {
          setTimeout(waitUntilDoneLoading.bind(this), 100);
        }
      }

      function waitUntilPdfReady() {
        if (this.pdfReady) {
          const pdf = document.getElementById('pdf');
          pdf.setAttribute('id', 'pdf-download'); // cloud looks for this id when generating PDF, set in Final component
          pdf.style.position = 'static';
          pdf.querySelector('.design-code strong').innerText = designCode;
          document.body.innerHTML = pdf.outerHTML;
        } else {
          setTimeout(waitUntilPdfReady.bind(this), 100);
        }
      }

      // Give DOM time to update
      setTimeout(() => {
        waitUntilDoneLoading.call(this);
      }, 100);
    },
  },
  components: {
    Header,
    LeftNav,
    Wall,
    Final,
    RightNav,
    Footer,
    Init,
    Error,
    TooNarrow,
  },
};
</script>

<style scoped>
main {
  background-color: #F9F9F9;
  margin-top: 65px;
  display: flex;
  justify-content: space-between;
}
</style>
