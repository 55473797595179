<template>
  <div class="wall-container">
    <div v-show="loading" class="loading-background">
      <img src="../assets/loading.gif" alt="Loading" class="loading-icon" />
    </div>
    <WallTooltip :open="activeStep === 0 && overflowTooltipOpen" text="One or more panels are outside the wall" show-alert type="error" @close="overflowTooltipOpen = false" />
    <WallTooltip :open="activeStep === 0 && invalidLayoutTooltipOpen" text="Invalid layout" type="error" show-alert @close="invalidLayoutTooltipOpen = false" />
    <WallTooltip :open="activeStep === 1 && powerTooltipOpen" text="Double click power supply icon to add a note" @close="powerTooltipOpen = false" />
    <WallTooltip :open="activeStep === 2 && mountingTooltipOpen" :text="mountingTooltipText" show-alert @close="mountingTooltipOpen = false" />
    <div v-show="activeStep === 0" :style="wallNameStyle">
      <div style="font-size: 12px;">{{ wallName }}</div>
    </div>
    <div v-show="activeStep === 0 && linkerView" class="linker-guide" :style="linkerGuideStyle" @click="linkerGuideOpen = true">
      Guide <img src="../assets/info.svg" alt="Guide" />
    </div>
    <div v-show="activeStep === 2" class="mounting-header" ref="mountingHeader">
      <div>
        <h2>Mounting Tape</h2>
        <div v-show="mountingTemporary">(Temporary installations)</div>
        <div v-show="!mountingTemporary">(Permanent installations)</div>
      </div>
      <div>
        <Toggle :left-active="mountingTemporary" left="Tape" :right="activeModel === 'NL42' ? 'Screw' : 'Plate'" @change="mountingTemporary = $event" />
        <div v-show="mountingTemporary">Use toggle to view screw placement for permanent installations.</div>
        <div v-show="!mountingTemporary">Use toggle to view tape placement for temporary installations.</div>
      </div>
    </div>
    <div class="wall-wrapper" ref="wrapper">
      <div id="wall" class="wall" :style="wall">
        <VueDragResizeRotate v-show="activeModel === 'NL22' && numLightPanels > 0"
          :w="panelControllerWidth" :h="panelControllerHeight" :angle="layoutRotation + controllerPosition.rotation" :scale="wallZoom"
          :x="controllerPosition.x" :y="controllerPosition.y" class="controller" @dragstop="({ x, y }) => snapController(x, y)">
          <div class="handle handle-tl"></div>
          <div class="handle handle-tr"></div>
          <div class="handle handle-bl"></div>
          <div class="handle handle-br"></div>
          <img src="../assets/lpcontroller.svg" alt="Controller" height="36" width="75" :style="panelControllerScale" />
        </VueDragResizeRotate>
        <VueDragResizeRotate class="dummy_panel" :w="panelWidth" :h="panelHeight" :style="dummyStyle"
          v-for="$index in numLightPanels" :key="'dummy_panel_'+($index - 1)"
          :x="positions.panel[$index - 1] ? positions.panel[$index - 1].x : -100"
          :y="positions.panel[$index - 1] ? positions.panel[$index - 1].y : -100">
        </VueDragResizeRotate>
        <VueDragResizeRotate v-show="activeStep !== 2 || !mountingTemporary" class="panel" :w="panelWidth" :h="panelHeight"
          :draggable="activeStep === 0" :resizable="false" :rotatable="false"
          v-for="$index in numLightPanels" :key="'panel_'+($index - 1)" :id="'panel_'+($index - 1)"
          :x="positions.panel[$index - 1] ? positions.panel[$index - 1].x : -100"
          :y="positions.panel[$index - 1] ? positions.panel[$index - 1].y : -100"
          :angle="layoutRotation" :scale="wallZoom"
          @dragstart="bringToFront('panel', $index - 1)" @dragstop="({ x, y }) => onDragStop('panel', $index - 1, x, y, true)">
          <div class="handle handle-tl"></div>
          <div class="handle handle-tr"></div>
          <div class="handle handle-bl"></div>
          <div class="handle handle-br"></div>
          <div :style="linkerViewStyle">
            <div v-if="positions.panel[$index - 1].rotation !== 0" class="linker linker-pivot" style="left: calc(50% - 1.5px);"></div>
            <div v-else class="linker linker-pivot" style="left: calc(50% - 1.5px); bottom: 0;"></div>
            <div v-if="positions.panel[$index - 1].rotation !== 0" class="linker linker-left" style="left: 25%; top: 50%; transform: rotate(240deg);"></div>
            <div v-else class="linker linker-left" style="left: 25%; top: 50%; transform: rotate(300deg);"></div>
            <div v-if="positions.panel[$index - 1].rotation !== 0" class="linker linker-right" style="right: 25%; top: 50%; transform: rotate(120deg);"></div>
            <div v-else class="linker linker-right" style="right: 25%; top: 50%; transform: rotate(60deg);"></div>
          </div>
          <svg width="148px" height="128px" viewBox="0 0 148 128" :style="panelScale">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-108.000000, -455.000000)">
                <g :transform="positions.panel[$index - 1].rotation !== 0 ? 'translate(182.000000, 518.931152) rotate(180) translate(-182.000000, -518.931152) translate(108.000000, 454.931152)' : 'translate(182.000000, 518.931152) rotate(0) translate(-182.000000, -518.931152) translate(108.000000, 454.931152)'">
                  <path d="M72.0363,0.5 L0.5003,124.404 C1.4523,126.054 1.2793,125.754 2.2313,127.404 L145.3043,127.404 C146.2563,125.754 146.0833,126.054 147.0363,124.404 L75.5003,0.5 L72.0363,0.5 Z" fill="#FEFEFE" />
                  <path d="M72.0363,0.5 L0.5003,124.404 C1.4523,126.054 1.2793,125.754 2.2313,127.404 L145.3043,127.404 C146.2563,125.754 146.0833,126.054 147.0363,124.404 L75.5003,0.5 L72.0363,0.5 Z" stroke="#F1F2F2" class="border" />
                  <path d="M10.8918,110.4038 L65.1078,16.4998 L82.4278,16.4998 L136.6438,110.4038 C131.8808,118.6538 132.7458,117.1538 127.9838,125.4038 L19.5518,125.4038 C14.7888,117.1538 15.6558,118.6538 10.8918,110.4038 Z" fill="#DADEE1" class="bg" />
                </g>
              </g>
            </g>
          </svg>
        </VueDragResizeRotate>
        <VueDragResizeRotate v-show="activeStep === 2 && mountingTemporary" :w="panelTapeWidth" :h="panelTapeHeight"
          :draggable="false" :resizable="false" :rotatable="false"
          v-for="$index in numLightPanels" :key="'panel_tape_'+($index - 1)"
          :x="positions.panel[$index - 1] ? positions.panel[$index - 1].x : -100"
          :y="positions.panel[$index - 1] ? positions.panel[$index - 1].y : -100"
          :angle="layoutRotation" class="panel_tape">
          <img v-show="positions.panel[$index - 1].rotation !== 0" src="../assets/lightpanelswithtape.svg" :style="panelTapeScale" />
          <img v-show="positions.panel[$index - 1].rotation === 0" src="../assets/lightpanelswithtapeR.svg" :style="panelTapeScale" />
        </VueDragResizeRotate>
        <VueDragResizeRotate v-show="activeStep === 2 && !mountingTemporary" :w="30" :h="30"
          :draggable="false" :resizable="false" :rotatable="false"
          v-for="(pos, $index) in lightPanelsMountingPlatePositions" :key="'panel_plate_'+($index - 1)"
          :x="pos ? pos[0] : -100" :y="pos ? pos[1] : -100" :angle="layoutRotation" class="panel_plate">
          <img src="../assets/lightpanelsmountplateandscrew.svg" alt="Mounting Plate and Screw" width="30" />
        </VueDragResizeRotate>
        <VueDragResizeRotate class="dummy_canvas" :w="canvasWidth" :h="canvasWidth" :style="dummyStyle"
          v-for="$index in numCanvases" :key="'dummy_canvas_'+($index - 1)"
          :x="positions.canvas[$index - 1] ? positions.canvas[$index - 1].x : -100"
          :y="positions.canvas[$index - 1] ? positions.canvas[$index - 1].y : -100">
        </VueDragResizeRotate>
        <VueDragResizeRotate v-show="activeStep !== 2" class="canvas" :w="canvasWidth" :h="canvasWidth"
          :draggable="activeStep === 0" :resizable="false" :rotatable="false"
          v-for="$index in numCanvases" :key="'canvas_'+($index - 1)" :id="'canvas_'+($index - 1)"
          :x="positions.canvas[$index - 1] ? positions.canvas[$index - 1].x : -100"
          :y="positions.canvas[$index - 1] ? positions.canvas[$index - 1].y : -100"
          :angle="layoutRotation" :scale="wallZoom"
          @dragstart="bringToFront('canvas', $index - 1)" @dragstop="({ x, y }) => onDragStop('canvas', $index - 1, x, y, true)">
          <div class="handle handle-tl"></div>
          <div class="handle handle-tr"></div>
          <div class="handle handle-bl"></div>
          <div class="handle handle-br"></div>
          <div v-if="positions.canvas[$index - 1].rotation === 0" :style="linkerViewStyle">
            <div class="linker linker-top" style="top: 0; left: 23%;"></div>
            <div class="linker linker-left" style="top: 18%; left: 0; transform: rotate(270deg);"></div>
            <div class="linker linker-bottom" style="bottom: 0; left: 23%;"></div>
            <div class="linker linker-right" style="top: 18%; left: 100%; transform: rotate(90deg);"></div>
            <img src="../assets/rotatecanvas.svg" alt="Rotate" class="linker-canvas-rotate" @click="rotateCanvasLinkers($index - 1, 90)" />
          </div>
          <div v-if="positions.canvas[$index - 1].rotation === 90" :style="linkerViewStyle">
            <div class="linker linker-top" style="top: 0; right: 23%;"></div>
            <div class="linker linker-left" style="top: 18%; left: 0; transform: rotate(270deg);"></div>
            <div class="linker linker-bottom" style="bottom: 0; right: 23%;"></div>
            <div class="linker linker-right" style="top: 18%; left: 100%; transform: rotate(90deg);"></div>
            <img src="../assets/rotatecanvas.svg" alt="Rotate" class="linker-canvas-rotate" @click="rotateCanvasLinkers($index - 1, 180)" />
          </div>
          <div v-if="positions.canvas[$index - 1].rotation === 180" :style="linkerViewStyle">
            <div class="linker linker-top" style="top: 0; right: 23%;"></div>
            <div class="linker linker-left" style="bottom: 18%; left: 0; transform: rotate(270deg);"></div>
            <div class="linker linker-bottom" style="bottom: 0; right: 23%;"></div>
            <div class="linker linker-right" style="bottom: 18%; left: 100%; transform: rotate(90deg);"></div>
            <img src="../assets/rotatecanvas.svg" alt="Rotate" class="linker-canvas-rotate" @click="rotateCanvasLinkers($index - 1, 270)" />
          </div>
          <div v-if="positions.canvas[$index - 1].rotation === 270" :style="linkerViewStyle">
            <div class="linker linker-top" style="top: 0; left: 23%;"></div>
            <div class="linker linker-left" style="bottom: 18%; left: 0; transform: rotate(270deg);"></div>
            <div class="linker linker-bottom" style="bottom: 0; left: 23%;"></div>
            <div class="linker linker-right" style="bottom: 18%; left: 100%; transform: rotate(90deg);"></div>
            <img src="../assets/rotatecanvas.svg" alt="Rotate" class="linker-canvas-rotate" @click="rotateCanvasLinkers($index - 1, 0)" />
          </div>
          <img v-if="$index === 1" src="../assets/canvascontrollerdots.svg" alt="Controller" :style="canvasControllerStyle" />
          <svg width="80px" height="79px" viewBox="0 0 80 79" :style="canvasScale">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-81.000000, -327.000000)">
                <g transform="translate(82.377048, 327.500000)">
                  <rect stroke="#F1F2F2" x="0" y="0" width="77.5" height="77.5" class="border" />
                  <rect fill="#DADEE1" x="2.75" y="2.75" width="72" height="72" rx="3.45" class="bg" />
                </g>
              </g>
            </g>
          </svg>
        </VueDragResizeRotate>
        <VueDragResizeRotate v-show="activeStep === 2 && mountingTemporary" :w="canvasTapeWidth" :h="canvasTapeHeight"
          :draggable="false" :resizable="false" :rotatable="false"
          v-for="$index in numCanvases" :key="'canvas_tape_'+($index - 1)"
          :x="positions.canvas[$index - 1] ? positions.canvas[$index - 1].x : -100"
          :y="positions.canvas[$index - 1] ? positions.canvas[$index - 1].y : -100"
          :angle="layoutRotation" class="canvas_tape">
          <img src="../assets/canvaswithtape.svg" :style="canvasTapeScale" />
        </VueDragResizeRotate>
        <VueDragResizeRotate v-show="activeStep === 2 && !mountingTemporary" :w="canvasPlateWidth" :h="canvasPlateHeight"
          :draggable="false" :resizable="false" :rotatable="false"
          v-for="$index in numCanvases" :key="'canvas_plate_'+($index - 1)"
          :x="positions.canvas[$index - 1] ? positions.canvas[$index - 1].x : -100"
          :y="positions.canvas[$index - 1] ? positions.canvas[$index - 1].y : -100"
          :angle="layoutRotation" class="canvas_plate">
          <img src="../assets/canvaswithplate.svg" :style="canvasPlateScale" />
        </VueDragResizeRotate>
        <VueDragResizeRotate v-show="activeModel === 'NL42' && numShapes > 0"
          :w="shapesControllerWidth" :h="shapesControllerHeight" :angle="layoutRotation + controllerPosition.rotation" :scale="wallZoom"
          :x="controllerPosition.x" :y="controllerPosition.y" class="controller" @dragstop="({ x, y }) => snapController(x, y)">
          <div class="handle handle-tl"></div>
          <div class="handle handle-tr"></div>
          <div class="handle handle-bl"></div>
          <div class="handle handle-br"></div>
          <img src="../assets/shapescontroller.svg" alt="Controller" height="38" width="81" :style="shapesControllerScale" />
        </VueDragResizeRotate>
        <VueDragResizeRotate class="dummy_shapes" :w="hexagonWidth" :h="hexagonHeight" :style="dummyStyle"
          v-for="$index in numHexagons" :key="'dummy_hexagon_'+($index - 1)"
          :x="positions.hexagon[$index - 1] ? positions.hexagon[$index - 1].x : -100"
          :y="positions.hexagon[$index - 1] ? positions.hexagon[$index - 1].y : -100">
        </VueDragResizeRotate>
        <VueDragResizeRotate class="hexagon shapes" :w="hexagonWidth" :h="hexagonHeight"
          :draggable="activeStep === 0" :resizable="false" :rotatable="false"
          v-for="$index in numHexagons" :key="'hexagon_'+($index - 1)" :id="'hexagon_'+($index - 1)"
          :x="positions.hexagon[$index - 1] ? positions.hexagon[$index - 1].x : -100"
          :y="positions.hexagon[$index - 1] ? positions.hexagon[$index - 1].y : -100"
          :angle="layoutRotation" :scale="wallZoom"
          @dragstart="bringToFront('hexagon', $index - 1)" @dragstop="({ x, y }) => onDragStop('hexagon', $index - 1, x, y, true)">
          <div class="handle handle-tl"></div>
          <div class="handle handle-tr"></div>
          <div class="handle handle-bl"></div>
          <div class="handle handle-br"></div>
          <div :style="linkerViewStyle">
            <div class="linker linker-top" style="left: calc(50% - 1.5px); top: 0;"></div>
            <div class="linker linker-left-top" style="left: 12.5%; top: 25%; transform: rotate(300deg);"></div>
            <div class="linker linker-left-bottom" style="left: 12.5%; top: 75%; transform: rotate(240deg);"></div>
            <div class="linker linker-bottom" style="left: calc(50% - 1.5px); bottom: 0;"></div>
            <div class="linker linker-right-bottom" style="right: 12.5%; top: 75%; transform: rotate(120deg);"></div>
            <div class="linker linker-right-top" style="right: 12.5%; top: 25%; transform: rotate(60deg);"></div>
          </div>
          <svg width="135px" height="116px" viewBox="0 0 135 116" :style="hexagonScale">
            <polygon fill="#FFFFFF" points="34.29 115.26 1.08 58 34.29 0.74 100.71 0.74 133.92 58 100.71 115.26 34.29 115.26" />
            <path fill="#DADEE1"  d="M100.42,1.24,133.34,58l-32.92,56.76H34.58L1.66,58,34.58,1.24h65.84m.58-1H34L.5,58,34,115.76h67L134.5,58,101,.24Z" />
            <path fill="#F1F2F2" d="M100.42,1.24,133.34,58l-32.92,56.76H34.58L1.66,58,34.58,1.24h65.84m.58-1H34L.5,58,34,115.76h67L134.5,58,101,.24Z" class="border" />
            <path fill="#DADEE1" d="M28.48,100.26,11.27,70.58a25,25,0,0,1,0-25.16L28.48,15.74a25.1,25.1,0,0,1,21.7-12.5H84.82a25.1,25.1,0,0,1,21.7,12.5l17.21,29.67a25.07,25.07,0,0,1,0,25.18l-17.21,29.67a25.1,25.1,0,0,1-21.7,12.5H50.18A25.1,25.1,0,0,1,28.48,100.26Z" class="bg" />
          </svg>
        </VueDragResizeRotate>
        <VueDragResizeRotate v-for="$index in numHexagons" :key="'mounting_hexagon_' + ($index - 1)"
          :draggable="false" :resizable="false" :rotatable="false" class="shapes_mounting" :id="'hexagon_mounting_' + ($index - 1)"
          :w="hexagonWidth" :h="hexagonHeight" :angle="layoutRotation" :style="mountingStyle"
          :x="positions.hexagon[$index - 1] ? positions.hexagon[$index - 1].x : -100"
          :y="positions.hexagon[$index - 1] ? positions.hexagon[$index - 1].y : -100">
          <img v-if="mountingTemporary" src="../assets/plate-tape.svg" alt="Mounting plate and mounting tape" />
          <img v-else src="../assets/plate-screw.svg" alt="Mounting plate and mounting screw" />
        </VueDragResizeRotate>
        <VueDragResizeRotate class="dummy_shapes" :w="triangleWidth" :h="triangleHeight" :style="dummyStyle"
          v-for="$index in numTriangles" :key="'dummy_triangle_'+($index - 1)"
          :x="positions.triangle[$index - 1] ? positions.triangle[$index - 1].x : -100"
          :y="positions.triangle[$index - 1] ? positions.triangle[$index - 1].y : -100">
        </VueDragResizeRotate>
        <VueDragResizeRotate class="triangle shapes" :w="triangleWidth" :h="triangleHeight"
          :draggable="activeStep === 0" :resizable="false" :rotatable="false"
          v-for="$index in numTriangles" :key="'triangle_'+($index - 1)" :id="'triangle_'+($index - 1)"
          :x="positions.triangle[$index - 1] ? positions.triangle[$index - 1].x : -100"
          :y="positions.triangle[$index - 1] ? positions.triangle[$index - 1].y : -100"
          :angle="layoutRotation" :scale="wallZoom"
          @dragstart="bringToFront('triangle', $index - 1)" @dragstop="({ x, y }) => onDragStop('triangle', $index - 1, x, y, true)">
          <div class="handle handle-tl"></div>
          <div class="handle handle-tr"></div>
          <div class="handle handle-bl"></div>
          <div class="handle handle-br"></div>
          <div :style="linkerViewStyle">
            <div v-if="positions.triangle[$index - 1].rotation !== 0" class="linker linker-pivot-left" style="left: calc(25% - 1.5px)"></div>
            <div v-else class="linker linker-pivot-left" style="left: calc(25% - 1.5px); bottom: 0;"></div>
            <div v-if="positions.triangle[$index - 1].rotation !== 0" class="linker linker-pivot-right" style="left: calc(75% - 1.5px)"></div>
            <div v-else class="linker linker-pivot-right" style="left: calc(75% - 1.5px); bottom: 0;"></div>
            <div v-if="positions.triangle[$index - 1].rotation !== 0" class="linker linker-top-left" style="left: 12.5%; top: 25%; transform: rotate(240deg);"></div>
            <div v-else class="linker linker-top-left" style="left: 37.5%; top: 25%; transform: rotate(300deg);"></div>
            <div v-if="positions.triangle[$index - 1].rotation !== 0" class="linker linker-bottom-left" style="left: 37.5%; top: 75%; transform: rotate(240deg);"></div>
            <div v-else class="linker linker-bottom-left" style="left: 12.5%; top: 75%; transform: rotate(300deg);"></div>
            <div v-if="positions.triangle[$index - 1].rotation !== 0" class="linker linker-top-right" style="right: 12.5%; top: 25%; transform: rotate(120deg);"></div>
            <div v-else class="linker linker-top-right" style="right: 37.5%; top: 25%; transform: rotate(60deg);"></div>
            <div v-if="positions.triangle[$index - 1].rotation !== 0" class="linker linker-bottom-right" style="right: 37.5%; top: 75%; transform: rotate(120deg);"></div>
            <div v-else class="linker linker-bottom-right" style="right: 12.5%; top: 75%; transform: rotate(60deg);"></div>
          </div>
          <svg width="134px" height="116px" viewBox="0 0 134 116.05" :style="triangleScale">
            <g :transform="positions.triangle[$index - 1].rotation !== 0 ? 'rotate(180 67 58)' : 'rotate(0)'">
              <polygon fill="#FFFFFF" points="0.65 115.55 66.79 1 132.92 115.55 0.65 115.55" />
              <path fill="#DADEE1" d="M66.79,2l65.26,113.05H1.52L66.79,2m0-2-67,116.05h134L66.79,0Z" />
              <path fill="#F1F2F2" d="M66.79,2l65.26,113.05H1.52L66.79,2m0-2-67,116.05h134L66.79,0Z" class="border" />
              <path fill="#DADEE1" d="M118.2,113.05H15.37a6,6,0,0,1-5.19-9L61.59,15A6,6,0,0,1,72,15l51.41,89.05A6,6,0,0,1,118.2,113.05Z" class="bg" />
            </g>
          </svg>
        </VueDragResizeRotate>
        <VueDragResizeRotate v-for="$index in numTriangles" :key="'mounting_triangle_' + ($index - 1)"
          :draggable="false" :resizable="false" :rotatable="false" class="shapes_mounting" :id="'triangle_mounting_' + ($index - 1)"
          :w="triangleWidth" :h="triangleHeight" :angle="layoutRotation" :style="mountingStyle"
          :x="positions.triangle[$index - 1] ? positions.triangle[$index - 1].x : -100"
          :y="positions.triangle[$index - 1] ? positions.triangle[$index - 1].y : -100">
          <img v-if="mountingTemporary" src="../assets/plate-tape.svg" alt="Mounting plate and mounting tape" />
          <img v-else src="../assets/plate-screw.svg" alt="Mounting plate and mounting screw" />
        </VueDragResizeRotate>
        <VueDragResizeRotate class="dummy_shapes" :w="miniTriangleWidth" :h="miniTriangleHeight" :style="dummyStyle"
          v-for="$index in numMiniTriangles" :key="'dummy_miniTriangle_'+($index - 1)"
          :x="positions.miniTriangle[$index - 1] ? positions.miniTriangle[$index - 1].x : -100"
          :y="positions.miniTriangle[$index - 1] ? positions.miniTriangle[$index - 1].y : -100">
        </VueDragResizeRotate>
        <VueDragResizeRotate class="miniTriangle shapes" :w="miniTriangleWidth" :h="miniTriangleHeight"
          :draggable="activeStep === 0" :resizable="false" :rotatable="false"
          v-for="$index in numMiniTriangles" :key="'miniTriangle_'+($index - 1)" :id="'miniTriangle_'+($index - 1)"
          :x="positions.miniTriangle[$index - 1] ? positions.miniTriangle[$index - 1].x : -100"
          :y="positions.miniTriangle[$index - 1] ? positions.miniTriangle[$index - 1].y : -100"
          :angle="layoutRotation" :scale="wallZoom"
          @dragstart="bringToFront('miniTriangle', $index - 1)" @dragstop="({ x, y }) => onDragStop('miniTriangle', $index - 1, x, y, true)">
          <div class="handle handle-tl"></div>
          <div class="handle handle-tr"></div>
          <div class="handle handle-bl"></div>
          <div class="handle handle-br"></div>
          <div :style="linkerViewStyle">
            <div v-if="positions.miniTriangle[$index - 1].rotation !== 0" class="linker linker-pivot" style="left: calc(50% - 1.5px)"></div>
            <div v-else class="linker linker-pivot" style="left: calc(50% - 1.5px); bottom: 0;"></div>
            <div v-if="positions.miniTriangle[$index - 1].rotation !== 0" class="linker linker-left" style="left: 25%; top: 50%; transform: rotate(240deg);"></div>
            <div v-else class="linker linker-left" style="left: 25%; top: 50%; transform: rotate(300deg);"></div>
            <div v-if="positions.miniTriangle[$index - 1].rotation !== 0" class="linker linker-right" style="right: 25%; top: 50%; transform: rotate(120deg);"></div>
            <div v-else class="linker linker-right" style="right: 25%; top: 50%; transform: rotate(60deg);"></div>
          </div>
          <svg width="67px" height="58px" viewBox="0 0 67 58.02" :style="miniTriangleScale">
            <g :transform="positions.miniTriangle[$index - 1].rotation !== 0 ? 'rotate(180 33.5 29)' : 'rotate(0)'">
              <polygon fill="#FFFFFF" points="0.65 57.52 33.29 1 65.92 57.52 0.65 57.52" />
              <path fill="#DADEE1" d="M33.29,2,65.05,57H1.52L33.29,2m0-2L-.21,58h67L33.29,0Z" />
              <path fill="#F1F2F2" d="M33.29,2,65.05,57H1.52L33.29,2m0-2L-.21,58h67L33.29,0Z" class="border" />
              <path fill="#DADEE1" d="M56.39,55H10.18a3,3,0,0,1-2.6-4.5l23.11-40a3,3,0,0,1,5.19,0L59,50.52A3,3,0,0,1,56.39,55Z" class="bg" />
            </g>
          </svg>
        </VueDragResizeRotate>
        <VueDragResizeRotate v-for="$index in numMiniTriangles" :key="'mounting_miniTriangle_' + ($index - 1)"
          :draggable="false" :resizable="false" :rotatable="false" class="shapes_mounting" :id="'miniTriangle_mounting_' + ($index - 1)"
          :w="miniTriangleWidth" :h="miniTriangleHeight" :angle="layoutRotation" :style="mountingStyle"
          :x="positions.miniTriangle[$index - 1] ? positions.miniTriangle[$index - 1].x : -100"
          :y="positions.miniTriangle[$index - 1] ? positions.miniTriangle[$index - 1].y : -100">
          <img v-if="mountingTemporary" src="../assets/plate-tape-mini.svg" alt="Mounting plate and mounting tape" />
          <img v-else src="../assets/plate-screw.svg" alt="Mounting plate and mounting screw" />
        </VueDragResizeRotate>
        <PowerSupply v-for="(powerSupply, $index) in powerSupplies" :key="'powerSupply_' + ($index - 1)" v-show="activeStep === 1"
          :wallWidth="wallElement.clientWidth" :wallHeight="wallElement.clientHeight" :wallScaleFactor="wallScaleFactor"
          :measurementUnit="measurementUnit" :activeStep="activeStep"
          :x="powerSupply.x" :y="powerSupply.y" :scale="wallZoom" :index="$index + 1"
          @update="setPowerSupplyPosition({ index: $index, ...$event })" @dragstop="snapPowerSupply($index)"
          :note="powerSupply.note" @note="setPowerSupplyNote({ index: $index, note: $event })" />
      </div>
    </div>
    <LinkerGuide v-if="activeModel !== ''" :model="activeModel" :open="linkerGuideOpen" @close="linkerGuideOpen = false" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import VueDragResizeRotate from './Drr/drr.vue';
import LinkerGuide from '../modals/LinkerGuide.vue';
import PowerSupply from './PowerSupply.vue';
import Toast from './Toast.vue';
import Toggle from './Toggle.vue';
import WallTooltip from './WallTooltip.vue';

export default {
  name: 'Wall',
  data() {
    return {
      loading: false,
      itemsOutsideWall: new Set(),
      overflowTooltipOpen: false,
      disconnectedItems: new Set(),
      invalidLayoutTooltipOpen: false,
      controllerLinkedTo: { type: '', index: 0, side: '' },
      powerTooltipOpen: false,
      mountingTooltipOpen: false,
      mountingTooltipText: '',
      windowWidth: document.documentElement.clientWidth,
      wallNamePosition: { left: 0, top: 0 },
      linkerGuidePosition: { left: 0, top: 0 },
      linkerGuideOpen: false,
      mountingTemporary: true,
      lightPanelsMountingPlatePositions: [],
    };
  },
  computed: {
    ...mapGetters([
      'activeModel',
      'numLightPanels',
      'numCanvases',
      'numHexagons',
      'numTriangles',
      'numMiniTriangles',
      'numShapes',
      'totalItemCount',
      'positions',
      'wallWidth',
      'wallHeight',
    ]),
    ...mapState([
      'isB2B',
      'activeId',
      'activeStep',
      'measurementUnit',
      'loadingFromDesignCode',
      'wallName',
      'wallZoom',
      'layoutRotation',
      'windowWidthPx',
      'windowHeightPx',
      'kits',
      'linkerView',
      'controllerPosition',
      'powerSupplies',
    ]),
    activeShapeName() {
      return this.activeModel === 'NL22' ? 'panel' : this.activeModel === 'NL29' ? 'canvas' : 'shapes';
    },
    itemElements() {
      return this.wallElement.getElementsByClassName(this.activeShapeName);
    },
    /*
     * For layout rotation, the point about which the shapes are rotated is the midpoint of the layout.
     * To calculate this midpoint, we need the left/right/top/bottom bounds of all the shapes without any rotation applied.
     * To achieve this, these so called dummy components are used.
     * They have the same size and position as the shape components but they are not rotated.
     * See the method getLayoutMidpoint for the calculation implementation.
     * The dummy components are hidden so that users cannot drag them.
     */
    dummyStyle() {
      return { visibility: 'hidden' };
    },
    dummyElements() {
      return this.wallElement.getElementsByClassName(`dummy_${this.activeShapeName}`);
    },
    /*
     * The sizes of the SVGs are hard coded. We use CSS scaling to scale them.
     * This component receives the wallWidth and wallHeight props in centimetres.
     * The wallScaleFactor computed property calculates the conversion factor from centimetres to pixels.
     * For example, return 0.5 * this.windowWidth / 220; means that 220 cm should correspond to 50% of windowWidth.
     * These values are arbitrary; if you want 50% of windowWidth to be 150 cm, change it to return 0.5 * this.windowWidth / 150;
     * wallScaleFactor should be the only property needed to change scaling. Everything else should adjust accordingly.
     * The scaling for each shape is found through trial and error to match the real size of the product.
     */
    panelScale() {
      return { transform: `scale(${0.165 * this.wallScaleFactor})`, 'transform-origin': 'top left' };
    },
    panelWidth() {
      return 148 * 0.165 * this.wallScaleFactor;
    },
    panelHeight() {
      return 128 * 0.165 * this.wallScaleFactor;
    },
    panelControllerScale() {
      return { transform: `scale(${0.18 * this.wallScaleFactor})`, 'transform-origin': 'top left' };
    },
    panelControllerWidth() {
      return 75 * 0.18 * this.wallScaleFactor + this.panelWidth * 0.4;
    },
    panelControllerHeight() {
      return 18 * 0.18 * this.wallScaleFactor;
    },
    panelTapeScale() {
      return { transform: `scale(${0.48 * this.wallScaleFactor})`, 'transform-origin': 'top left' };
    },
    panelTapeWidth() {
      return 55 * 0.48 * this.wallScaleFactor;
    },
    panelTapeHeight() {
      return 49 * 0.48 * this.wallScaleFactor;
    },
    canvasScale() {
      return { transform: `scale(${0.19 * this.wallScaleFactor})`, 'transform-origin': 'top left' };
    },
    canvasWidth() {
      return 80 * 0.19 * this.wallScaleFactor;
    },
    canvasHeight() {
      return 79 * 0.19 * this.wallScaleFactor;
    },
    canvasControllerStyle() {
      const { rotation } = this.positions.canvas[0];
      let left, top;

      if (rotation === 0) {
        left = '50%';
        top = '85%';
      } else if (rotation === 90) {
        left = '-5%';
        top = '70%';
      } else if (rotation === 180) {
        left = '10%';
        top = '10%';
      } else if (rotation === 270) {
        left = '70%';
        top = '25%';
      }

      return {
        position: 'absolute',
        left,
        top,
        transform: `rotate(${rotation}deg)`,
        width: `${this.canvasWidth * 0.4}px`,
        zIndex: 2,
      };
    },
    canvasTapeScale() {
      return { transform: `scale(${0.36 * this.wallScaleFactor})`, 'transform-origin': 'top left' };
    },
    canvasTapeWidth() {
      return 45 * 0.36 * this.wallScaleFactor;
    },
    canvasTapeHeight() {
      return 46 * 0.36 * this.wallScaleFactor;
    },
    canvasPlateScale() {
      return { transform: `scale(${0.33 * this.wallScaleFactor})`, 'transform-origin': 'top left' };
    },
    canvasPlateWidth() {
      return 52 * 0.33 * this.wallScaleFactor;
    },
    canvasPlateHeight() {
      return 55 * 0.33 * this.wallScaleFactor;
    },
    hexagonScale() {
      return { transform: `scale(${0.17 * this.wallScaleFactor})`, 'transform-origin': 'top left' };
    },
    hexagonWidth() {
      return 135 * 0.17 * this.wallScaleFactor;
    },
    hexagonHeight() {
      return 116 * 0.17 * this.wallScaleFactor;
    },
    triangleScale() {
      return { transform: `scale(${0.17 * this.wallScaleFactor})`, 'transform-origin': 'top left' };
    },
    triangleWidth() {
      return 134 * 0.17 * this.wallScaleFactor;
    },
    triangleHeight() {
      return 116 * 0.17 * this.wallScaleFactor;
    },
    miniTriangleScale() {
      return { transform: `scale(${0.17 * this.wallScaleFactor})`, 'transform-origin': 'top left' };
    },
    miniTriangleWidth() {
      return 67 * 0.17 * this.wallScaleFactor;
    },
    miniTriangleHeight() {
      return 58 * 0.17 * this.wallScaleFactor;
    },
    shapesControllerScale() {
      return { transform: `scale(${0.14 * this.wallScaleFactor})`, 'transform-origin': 'top left' };
    },
    shapesControllerWidth() {
      return 81 * 0.14 * this.wallScaleFactor;
    },
    shapesControllerHeight() {
      return 19 * 0.14 * this.wallScaleFactor;
    },
    wallScaleFactor() {
      return 0.5 * this.windowWidth / 150;
    },
    wall() {
      return {
        width: `${this.wallWidth * this.wallScaleFactor}px`,
        height: `${this.wallHeight * this.wallScaleFactor}px`,
        overflow: 'hidden',
        transform: `scale(${this.wallZoom})`,
        'transform-origin': this.wallZoom >= 1 ? 'top left' : 'center',
      };
    },
    wallElement() {
      return document.getElementById('wall');
    },
    wallNameStyle() {
      const { left, top } = this.wallNamePosition;
      return {
        position: 'fixed',
        left: `${left}px`,
        top: `${top}px`,
      };
    },
    linkerGuideStyle() {
      const { left, top } = this.linkerGuidePosition;
      return {
        position: 'fixed',
        left: `${left}px`,
        top: `${top}px`,
      };
    },
    linkerViewStyle() {
      return { visibility: this.activeStep <= 1 && this.linkerView ? 'visible' : 'hidden' };
    },
    mountingStyle() {
      if (this.activeStep === 2) {
        return {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2,
        };
      } else {
        return { display: 'none' };
      }
    },
    mountingTapeElements() {
      return document.getElementsByClassName(`${this.activeShapeName}_tape`);
    },
    mountingPlateElements() {
      return document.getElementsByClassName(`${this.activeShapeName}_plate`);
    },
    mountingElements() {
      return document.getElementsByClassName(`${this.activeShapeName}_mounting`);
    },
  },
  created() {
    // Since the panels are inside rectangular divs, it is possible to drag the wrong panel
    // This is mostly an issue for light panels/triangles
    // We are overriding the mousedown/touchstart event and choosing which panel to apply it on ourselves
    // Note that this requires the Drr component to have event.stopPropagation removed so we can intercept the event here
    for (const eventName of ['mousedown', 'touchstart']) {
      document.documentElement.addEventListener(eventName, (e) => {
        if (this.itemElements.length === 0 || this.activeStep !== 0) return;

        // only make sure this function runs if the event was on a panel
        let { target } = e;
        while (target.parentNode && target.tagName !== 'DIV') {
          target = target.parentNode;
        }
        if (!target.classList || !target.classList.contains('drr')) return;

        // dispatching a mouseup/touchend event will cause Drr to "cancel" the current mousedown/touchstart event
        document.documentElement.dispatchEvent(new Event(eventName === 'mousedown' ? 'mouseup' : 'touchend'));

        // find the panel closest to the event coordinates
        const eventCoords = eventName === 'mousedown' ? [e.pageX, e.pageY] : [e.touches[0].pageX, e.touches[0].pageY];
        const itemsAndController = [...this.itemElements, ...document.getElementsByClassName('controller')];
        const [closest] = itemsAndController.reduce(([closest, minDistance], item) => {
          const midpoint = this.getMidpoint(item);
          const distance = this.getDistance(midpoint, eventCoords);
          if (distance < minDistance) {
            return [item, distance];
          }
          return [closest, minDistance];
        }, [null, Number.POSITIVE_INFINITY]);

        const event = new Event(eventName);
        if (eventName === 'mousedown') {
          event.pageX = e.pageX;
          event.pageY = e.pageY;
        } else {
          event.touches = [new Touch({
            identifier: Date.now(),
            target: closest,
            pageX: e.touches[0].pageX,
            pageY: e.touches[0].pageY,
          })];
        }
        closest.dispatchEvent(event);
      });
    }

    this.$root.$on('set-step', (step) => {
      if (this.itemsOutsideWall.size > 0) {
        this.overflowTooltipOpen = true;
      } else if (this.disconnectedItems.size > 0) {
        this.invalidLayoutTooltipOpen = true;
      } else if (this.totalItemCount > 0) {
        this.setStep(step);
      }
    });

    this.$root.$on('undo-redo', async () => {
      this.rotateItems();
      await this.centreItems();
      this.highlightItemsOutsideWall();
      this.highlightDisconnectedItems();
      this.updateDesignSize();
      this.generateLayoutString();
    });
  },
  mounted() {
    const { left, right, top, height, width } = this.wallElement.getBoundingClientRect();
    this.setPxDimensions({ width, height });

    this.wallNamePosition = { left, top: top - 35 };
    this.linkerGuidePosition = { left: right - 100, top: top - 45 };
    addEventListener('resize', () => {
      const { left, right, top } = this.wallElement.getBoundingClientRect();
      this.wallNamePosition = { left, top: top - 35 };
      this.linkerGuidePosition = { left: right - 100, top: top - 45 };
    });

    this.$refs.mountingHeader.style.width = `${width}px`;
  },
  watch: {
    async layoutRotation() {
      this.rotateItems();
      await this.$nextTick();
      this.snapController(null, null, this.controllerLinkedTo);
      await this.centreItems();
      this.highlightItemsOutsideWall();
      this.updateDesignSize();
    },
    wallWidth() {
      setTimeout(async () => {
        if (this.activeStep === 0) {
          await this.centreItems();
          this.highlightItemsOutsideWall();
        }
        const { left, right, width, height } = this.wallElement.getBoundingClientRect();
        this.setPxDimensions({ width, height });
        this.$set(this.wallNamePosition, 'left', left);
        this.$set(this.linkerGuidePosition, 'left', right - 100);
        if (this.$refs.wrapper.scrollWidth > this.$refs.wrapper.clientWidth) {
          this.$set(this.linkerGuidePosition, 'left', this.$refs.wrapper.clientWidth + 20);
        }
      }, 10);
    },
    wallHeight() {
      setTimeout(async () => {
        if (this.activeStep === 0) {
          await this.centreItems();
          this.highlightItemsOutsideWall();
        }
        const { clientWidth: width, clientHeight: height } = this.wallElement;
        this.setPxDimensions({ width, height });
      }, 10);
    },

    async activeModel(newModel) {
      await this.$nextTick();
      this.rotateItems();
      this.updateDesignSize();
      if (newModel !== 'NL22') {
        this.lightPanelsMountingPlatePositions = [];
      }
      if (newModel === 'NL42') {
        this.mountingTooltipText = 'Screws are not included in the kit';
      } else {
        this.mountingTooltipText = 'Plates are not included in the kit';
      }
    },

    numLightPanels(qty, oldQty) {
      this.updateItemCount('panel', oldQty, qty);
    },
    numCanvases(qty, oldQty) {
      this.updateItemCount('canvas', oldQty, qty);
    },
    numHexagons(qty, oldQty) {
      this.updateItemCount('hexagon', oldQty, qty);
    },
    numTriangles(qty, oldQty) {
      this.updateItemCount('triangle', oldQty, qty);
    },
    numMiniTriangles(qty, oldQty) {
      this.updateItemCount('miniTriangle', oldQty, qty);
    },

    async loadingFromDesignCode(value) {
      if (!value) return;
      this.loading = true;
      // wait for DOM to load
      await new Promise((r) => setTimeout(r, 10));

      // adjust positions based on window size
      const { clientHeight, clientWidth } = document.documentElement;
      const allPositions = ['panel', 'canvas', 'hexagon', 'triangle', 'miniTriangle'].reduce((positions, type) => ({
        ...positions,
        [type]: this.positions[type].map(({ x, y, rotation }) => ({
          x: x * clientWidth / this.windowWidthPx,
          y: y * clientWidth / this.windowWidthPx,
          rotation,
          isSnapped: true,
        })),
      }), {});
      this.setAllPositions(allPositions);

      this.setControllerPosition({
        x: this.controllerPosition.x * clientWidth / this.windowWidthPx,
        y: this.controllerPosition.y * clientWidth / this.windowWidthPx,
        rotation: this.controllerPosition.rotation,
      });

      for (let i = 0; i < this.powerSupplies.length; i++) {
        this.setPowerSupplyPosition({
          index: i,
          ...this.powerSupplies[i],
          x: this.powerSupplies[i].x * clientWidth / this.windowWidthPx,
          y: this.powerSupplies[i].y * clientHeight / this.windowHeightPx,
        });
        this.setPowerSupplyNote({
          index: i,
          note: this.powerSupplies[i].note,
        });
      }

      for (let i = 0; i < this.numLightPanels; i++) {
        await this.onDragStop('panel', i, null, null, i === this.numLightPanels - 1);
      }
      for (let i = 0; i < this.numCanvases; i++) {
        await this.onDragStop('canvas', i, null, null, i === this.numCanvases - 1);
      }
      for (let i = 0; i < this.numHexagons; i++) {
        await this.onDragStop('hexagon', i, null, null, i === this.numHexagons - 1);
      }
      for (let i = 0; i < this.numTriangles; i++) {
        await this.onDragStop('triangle', i, null, null, i === this.numTriangles - 1);
      }
      for (let i = 0; i < this.numMiniTriangles; i++) {
        await this.onDragStop('miniTriangle', i, null, null, i === this.numMiniTriangles - 1);
      }

      const { clientWidth: wallWidth, clientHeight: wallHeight } = this.wallElement;
      this.setPxDimensions({ width: wallWidth, height: wallHeight });
      this.doneLoadingFromDesignCode();
      this.loading = false;
    },

    async activeStep(step) {
      await this.$nextTick();
      // do not re-calculate transform origins when going to final step
      if (step !== 3) {
        this.rotateItems();
      }
      // give space to scroll on mounting step
      if (step === 2) {
        this.$refs.wrapper.style.maxHeight = 'calc(100vh - 375px)';
      } else {
        this.$refs.wrapper.style.maxHeight = 'calc(100vh - 250px)';
      }
      if (step === 0) {
        // set to true because if user changes design, will need to recalculate mounting plates in mounting step
        this.mountingTemporary = true;
      } else if (step === 1) {
        for (let i = 0; i < this.powerSupplies.length; i++) {
          this.snapPowerSupply(i);
        }
        this.powerTooltipOpen = true;
      } else if (step === 2 && this.activeModel === 'NL42') {
        // mounting plate styles are set here because they must be in the DOM
        this.setMountingPlateStyles();
      }
    },

    async mountingTemporary(value) {
      await this.$nextTick();
      if (!value && this.activeModel === 'NL22') {
        this.setLightPanelsMountingPlatePositions();
      } else if (this.activeModel === 'NL42') {
        this.setMountingPlateStyles();
      }
      this.mountingTooltipOpen = !value;
    },

    'powerSupplies.length'(newCount, oldCount) {
      for (let i = oldCount; i < newCount; i++) {
        // give time for PowerSupply component to mount
        setTimeout(() => this.snapPowerSupply(i), 10);
      }
    },
  },
  methods: {
    ...mapActions([
      'setStep',
    ]),
    ...mapMutations([
      'setPxDimensions',
      'setDesignDimensions',
      'setPosition',
      'setAllPositions',
      'setActiveId',
      'setControllerPosition',
      'setPowerSupplyPosition',
      'setPowerSupplyNote',
      'doneLoadingFromDesignCode',
      'pushStateToUndoStack',
      'setLayoutStringData',
    ]),
    updateDesignSize() {
      if (this.itemElements.length === 0) {
        this.setDesignDimensions({ width: 0, height: 0 });
      } else {
        const { left, right, top, bottom } = Array.from(this.itemElements).reduce((values, item) => {
          const { left, right, top, bottom } = item.getBoundingClientRect();
          return {
            left: Math.min(values.left, left),
            right: Math.max(values.right, right),
            top: Math.min(values.top, top),
            bottom: Math.max(values.bottom, bottom),
          };
        }, {
          left: Number.POSITIVE_INFINITY,
          right: Number.NEGATIVE_INFINITY,
          top: Number.POSITIVE_INFINITY,
          bottom: Number.NEGATIVE_INFINITY,
        });
        this.setDesignDimensions({
          width: (right - left) / this.wallScaleFactor,
          height: (bottom - top) / this.wallScaleFactor,
        });
      }
    },
    itemCornersAsCoords(item) {
      const { topLeft, topRight, bottomLeft, bottomRight } = this.getItemCorners(item);
      return {
        topLeft: [topLeft.left, topLeft.top],
        topRight: [topRight.left, topRight.top],
        bottomLeft: [bottomLeft.left, bottomLeft.top],
        bottomRight: [bottomRight.left, bottomRight.top],
      };
    },
    calculateMidpoint(a, b) {
      return [(a[0] + b[0]) * 0.5, (a[1] + b[1]) * 0.5];
    },
    adjustCoordsToWall(coords) {
      const { top, left } = this.wallElement.getBoundingClientRect();
      return coords.map(([x, y]) => [(x - left) / this.wallZoom, (y - top) / this.wallZoom]);
    },
    getPanelSides(item) {
      const { topLeft, topRight, bottomLeft, bottomRight } = this.itemCornersAsCoords(item);
      const isRotated = this.isRotated(item);
      const pivot = isRotated
        ? this.calculateMidpoint(topLeft, topRight)
        : this.calculateMidpoint(bottomLeft, bottomRight);
      return this.adjustCoordsToWall([
        pivot,
        this.calculateMidpoint(pivot, isRotated ? bottomLeft : topLeft),
        this.calculateMidpoint(pivot, isRotated ? bottomRight : topRight),
      ]);
    },
    getCanvasSides(item) {
      const { topLeft, topRight, bottomLeft, bottomRight } = this.itemCornersAsCoords(item);
      const { index } = this.getTypeAndIndexFromItem(item);
      const { rotation } = this.positions.canvas[index];
      if (rotation === 0) {
        const left = [topLeft[0] * 0.75 + bottomLeft[0] * 0.25, topLeft[1]* 0.75 + bottomLeft[1] * 0.25];
        const top = [topLeft[0] * 0.75 + topRight[0] * 0.25, topLeft[1]* 0.75 + topRight[1] * 0.25];
        const right = [topRight[0] * 0.75 + bottomRight[0] * 0.25, topRight[1] * 0.75 + bottomRight[1] * 0.25];
        const bottom = [bottomLeft[0] * 0.75 + bottomRight[0] * 0.25, bottomLeft[1] * 0.75 + bottomRight[1] * 0.25];
        return this.adjustCoordsToWall([left, top, right, bottom]);
      } else if (rotation === 90) {
        const left = [topLeft[0] * 0.75 + bottomLeft[0] * 0.25, topLeft[1]* 0.75 + bottomLeft[1] * 0.25];
        const top = [topLeft[0] * 0.25 + topRight[0] * 0.75, topLeft[1]* 0.25 + topRight[1] * 0.75];
        const right = [topRight[0] * 0.75 + bottomRight[0] * 0.25, topRight[1] * 0.75 + bottomRight[1] * 0.25];
        const bottom = [bottomLeft[0] * 0.25 + bottomRight[0] * 0.75, bottomLeft[1] * 0.25 + bottomRight[1] * 0.75];
        return this.adjustCoordsToWall([left, top, right, bottom]);
      } else if (rotation === 180) {
        const left = [topLeft[0] * 0.25 + bottomLeft[0] * 0.75, topLeft[1]* 0.25 + bottomLeft[1] * 0.75];
        const top = [topLeft[0] * 0.25 + topRight[0] * 0.75, topLeft[1]* 0.25 + topRight[1] * 0.75];
        const right = [topRight[0] * 0.25 + bottomRight[0] * 0.75, topRight[1] * 0.25 + bottomRight[1] * 0.75];
        const bottom = [bottomLeft[0] * 0.25 + bottomRight[0] * 0.75, bottomLeft[1] * 0.25 + bottomRight[1] * 0.75];
        return this.adjustCoordsToWall([left, top, right, bottom]);
      } else if (rotation === 270) {
        const left = [topLeft[0] * 0.25 + bottomLeft[0] * 0.75, topLeft[1]* 0.25 + bottomLeft[1] * 0.75];
        const top = [topLeft[0] * 0.75 + topRight[0] * 0.25, topLeft[1]* 0.75 + topRight[1] * 0.25];
        const right = [topRight[0] * 0.25 + bottomRight[0] * 0.75, topRight[1] * 0.25 + bottomRight[1] * 0.75];
        const bottom = [bottomLeft[0] * 0.75 + bottomRight[0] * 0.25, bottomLeft[1] * 0.75 + bottomRight[1] * 0.25];
        return this.adjustCoordsToWall([left, top, right, bottom]);
      }
    },
    getHexagonSides(item) {
      const { topLeft, topRight, bottomLeft, bottomRight } = this.itemCornersAsCoords(item);
      const pivot = this.calculateMidpoint(topLeft, topRight);
      const pivotOpposite = this.calculateMidpoint(bottomLeft, bottomRight);
      const middleLeft = this.calculateMidpoint(topLeft, bottomLeft);
      const middleRight = this.calculateMidpoint(topRight, bottomRight);
      const tlCorner = [topLeft[0] * 0.75 + topRight[0] * 0.25, topLeft[1]* 0.75 + topRight[1] * 0.25];
      const trCorner = [topLeft[0] * 0.25 + topRight[0] * 0.75, topLeft[1] * 0.25 + topRight[1] * 0.75];
      const blCorner = [bottomLeft[0] * 0.75 + bottomRight[0] * 0.25, bottomLeft[1] * 0.75 + bottomRight[1] * 0.25];
      const brCorner = [bottomLeft[0] * 0.25 + bottomRight[0] * 0.75, bottomLeft[1] * 0.25 + bottomRight[1] * 0.75];
      return this.adjustCoordsToWall([
        pivot,
        this.calculateMidpoint(tlCorner, middleLeft),
        this.calculateMidpoint(middleLeft, blCorner),
        pivotOpposite,
        this.calculateMidpoint(brCorner, middleRight),
        this.calculateMidpoint(middleRight, trCorner),
      ]);
    },
    getTriangleSides(item) {
      const { topLeft, topRight, bottomLeft, bottomRight } = this.itemCornersAsCoords(item);
      const isRotated = this.isRotated(item);
      const pivot = isRotated
        ? this.calculateMidpoint(topLeft, topRight)
        : this.calculateMidpoint(bottomLeft, bottomRight);
      const pivotOpposite = isRotated
        ? this.calculateMidpoint(bottomLeft, bottomRight)
        : this.calculateMidpoint(topLeft, topRight);
      const middleLeft = this.calculateMidpoint(pivot, isRotated ? bottomLeft : topLeft);
      const middleRight = this.calculateMidpoint(pivot, isRotated ? bottomRight : topRight);
      return this.adjustCoordsToWall([
        this.calculateMidpoint(pivot, isRotated ? topLeft : bottomLeft),
        this.calculateMidpoint(pivot, isRotated ? topRight : bottomRight),
        this.calculateMidpoint(middleLeft, isRotated ? topLeft : bottomLeft),
        this.calculateMidpoint(middleLeft, pivotOpposite),
        this.calculateMidpoint(middleRight, isRotated ? topRight : bottomRight),
        this.calculateMidpoint(middleRight, pivotOpposite),
      ]);
    },
    getSides(item) {
      const { type } = this.getTypeAndIndexFromItem(item);
      switch (type) {
        case 'panel': return this.getPanelSides(item);
        case 'canvas': return this.getCanvasSides(item);
        case 'hexagon': return this.getHexagonSides(item);
        case 'triangle': return this.getTriangleSides(item);
        case 'miniTriangle': return this.getPanelSides(item);
        default: return [];
      }
    },
    // return side coordinates along with the item element and side "name"
    getSidesWithContext(item) {
      const { type } = this.getTypeAndIndexFromItem(item);
      let sides = [];
      switch (type) {
        case 'panel':
          sides = this.getPanelSides(item);
          sides[0] = { item, side: 'pivot', coords: sides[0] };
          sides[1] = { item, side: 'left', coords: sides[1] };
          sides[2] = { item, side: 'right', coords: sides[2] };
          return sides;
        case 'canvas':
          sides = this.getCanvasSides(item);
          sides[0] = { item, side: 'left', coords: sides[0] };
          sides[1] = { item, side: 'top', coords: sides[1] };
          sides[2] = { item, side: 'right', coords: sides[2] };
          sides[3] = { item, side: 'bottom', coords: sides[3] };
          return sides;
        case 'hexagon':
          sides = this.getHexagonSides(item);
          sides[0] = { item, side: 'top', coords: sides[0] };
          sides[1] = { item, side: 'leftTop', coords: sides[1] };
          sides[2] = { item, side: 'leftBottom', coords: sides[2] };
          sides[3] = { item, side: 'bottom', coords: sides[3] };
          sides[4] = { item, side: 'rightBottom', coords: sides[4] };
          sides[5] = { item, side: 'rightTop', coords: sides[5] };
          return sides;
        case 'triangle':
          sides = this.getTriangleSides(item);
          sides[0] = { item, side: 'pivotLeft', coords: sides[0] };
          sides[1] = { item, side: 'pivotRight', coords: sides[1] };
          sides[2] = { item, side: 'left1', coords: sides[2] };
          sides[3] = { item, side: 'leftPivot', coords: sides[3] };
          sides[4] = { item, side: 'right1', coords: sides[4] };
          sides[5] = { item, side: 'rightPivot', coords: sides[5] };
          return sides;
        case 'miniTriangle':
          sides = this.getPanelSides(item);
          sides[0] = { item, side: 'pivot', coords: sides[0] };
          sides[1] = { item, side: 'left', coords: sides[1] };
          sides[2] = { item, side: 'right', coords: sides[2] };
          return sides;
        default: return sides;
      }
    },
    // by adding panels in chunks, we allow the browser to have time to update the UI to prevent freezing
    process(arr) {
      return new Promise((resolve) => {
        let i = 0;
        const chunk = 5;
        async function processChunk() {
          let count = chunk;
          while (count > 0 && i < arr.length) {
            const { type, index, x, y, lastItem } = arr[i];
            await this.onDragStop(type, index, x, y, lastItem);
            i++;
            count--;
          }

          if (i < arr.length) {
            setTimeout(processChunk.bind(this), 1);
          } else {
            resolve();
          }
        }
        processChunk.call(this);
      });
    },
    async addOrRemovePanels(type, oldQty, newQty) {
      this.loading = true;
      // loading icon does not show without delay for some reason ($nextTick does not work)
      await new Promise((r) => setTimeout(r, 1));
      if (newQty < oldQty) {
        await this.$nextTick();
        await this.centreItems();
        this.snapController();
        this.highlightItemsOutsideWall();
        this.highlightDisconnectedItems();
        this.updateDesignSize();
        this.generateLayoutString();
      } else if (this.positions[type].some(({ isSnapped }) => !isSnapped)) {
        const { clientHeight: wallHeight, clientWidth: wallWidth } = this.wallElement;
        const newPanelData = [];
        for (let i = oldQty; i < newQty; i++) {
          // if width >= height, provide a larger range of x coordinates and vice versa
          const percentX = wallWidth >= wallHeight ? Math.random() * 0.4 + 0.3 : Math.random() * 0.2 + 0.4;
          const x = wallWidth * percentX;
          const percentY = wallWidth >= wallHeight ? Math.random() * 0.2 + 0.4 : Math.random() * 0.4 + 0.3;
          const y = wallHeight * percentY;
          newPanelData.push({ type, index: i, x, y, lastItem: i === newQty - 1 });
        }
        await this.process(newPanelData);
      }
      this.loading = false;
    },
    showToast(panelType) {
      if (panelType === 'panel') panelType = 'Light Panels';
      else if (panelType === 'canvas') panelType = 'Canvas';
      else if (panelType === 'hexagon') panelType = 'Hexagons';
      else if (panelType === 'triangle') panelType = 'Triangles';
      else if (panelType === 'miniTriangle') panelType = 'Mini Triangles';

      const { model, type, size } = [...this.kits].reverse().find(({ model }) => model === panelType);
      this.$toast({
        component: Toast,
        props: {
          title: `${model} ${type} (${size} panels)`,
          message: `A ${size} panels ${model} ${type} has been added to your shopping list.`,
        },
      });
    },
    async updateItemCount(type, oldQty, qty) {
      if (!this.loadingFromDesignCode) {
        await this.addOrRemovePanels(type, oldQty, qty);
        if (qty > oldQty) {
          this.showToast(type);
        }
      }
    },
    bringToFront(type, index) {
      for (const item of this.itemElements) item.style.zIndex = 0;
      document.getElementById(`${type}_${index}`).style.zIndex = 1;
    },
    // if index is not given, item must be an HTML element
    rotate(item, index) {
      if (typeof index !== 'undefined') {
        item = document.getElementById(`${item}_${index}`);
      }

      const { type, index: i } = this.getTypeAndIndexFromItem(item);
      const { x, y, rotation } = this.positions[type][i];
      this.setPosition({
        type,
        index: i,
        x,
        y,
        rotation: rotation === 0 ? 180 : 0,
      });
    },
    // value1/value2 [0] is the x coordinate, value1/value2[1] is the y coordinate
    getDistance(value1, value2) {
      return ((value1[0] - value2[0]) ** 2 + (value1[1] - value2[1]) ** 2) ** 0.5;
    },
    isRotated(item) {
      const { type, index } = this.getTypeAndIndexFromItem(item);
      return this.positions[type][index].rotation !== 0;
    },
    getTypeAndIndexFromItem(item) {
      const parts = item.id.split('_');
      return { type: parts[0], index: parseInt(parts[1]) };
    },
    getItemCorners(item) {
      return {
        topLeft: item.getElementsByClassName('handle-tl')[0].getBoundingClientRect(),
        topRight: item.getElementsByClassName('handle-tr')[0].getBoundingClientRect(),
        bottomLeft: item.getElementsByClassName('handle-bl')[0].getBoundingClientRect(),
        bottomRight: item.getElementsByClassName('handle-br')[0].getBoundingClientRect(),
      };
    },
    // given an array of sides in arbitrary order,
    // return the ith closest side where i = snapIndex
    findSnapIndex(sides, snapIndex) {
      const sidesSorted = [...sides].sort((a, b) => a - b);
      const sideToSnap = sidesSorted[snapIndex];
      return sides.findIndex((side) => side === sideToSnap);
    },
    async verifySnapPanels(item, snapX, snapY) {
      // Do not allow snapping if it would place a panel on top of another one
      for (const panel of this.wallElement.getElementsByClassName('panel')) {
        const { index } = this.getTypeAndIndexFromItem(panel);
        const { x, y, isSnapped } = this.positions.panel[index];
        if (item !== panel && isSnapped) {
          const distanceBetweenMidpoints = this.getDistance([snapX, snapY], [x, y]);
          if (distanceBetweenMidpoints < this.panelWidth * 0.49) {
            return false;
          }
        }
      }
      const { index } = this.getTypeAndIndexFromItem(item);
      this.setPosition({ type: 'panel', index, x: snapX, y: snapY, rotation: this.positions.panel[index].rotation });
      await this.$nextTick();
      return true;
    },
    snapPanels(item, nearest, sideIndex) {
      if (this.isRotated(item) === this.isRotated(nearest)) this.rotate(item);

      const { type, index: nearestIndex } = this.getTypeAndIndexFromItem(nearest);
      const { x: nearestX, y: nearestY } = this.positions[type][nearestIndex];
      const isPanel = type === 'panel';
      const WIDTH = isPanel ? this.panelWidth : this.miniTriangleWidth;
      const HEIGHT = isPanel ? this.panelHeight : this.miniTriangleHeight;

      const verifySnap = (isPanel ? this.verifySnapPanels : this.verifySnapShapes).bind(this);

      const SNAP_THRESHOLD = Number.POSITIVE_INFINITY;
      if (this.isRotated(item)) {
        const [itemTop, itemLeft, itemRight] = this.getPanelSides(item);
        const [nearestBottom, nearestLeft, nearestRight] = this.getPanelSides(nearest);
        const leftRight = this.getDistance(itemLeft, nearestRight);
        const rightLeft = this.getDistance(itemRight, nearestLeft); 
        const topBottom = this.getDistance(itemTop, nearestBottom);

        const sides = [leftRight, rightLeft, topBottom];
        const snapIndex = this.findSnapIndex(sides, sideIndex);
  
        if (snapIndex === 0 && leftRight <= SNAP_THRESHOLD) {
          return verifySnap(item, nearestX + WIDTH * (isPanel ? 0.52 : 0.5), nearestY - HEIGHT * (isPanel ? 0.02 : 0));
        } else if (snapIndex === 1 && rightLeft <= SNAP_THRESHOLD) {
          return verifySnap(item, nearestX - WIDTH * (isPanel ? 0.52 : 0.5), nearestY - HEIGHT * (isPanel ? 0.02 : 0));
        } else if (snapIndex === 2 && topBottom <= SNAP_THRESHOLD) {
          return verifySnap(item, nearestX, nearestY + HEIGHT);
        }
      } else {
        const [itemBottom, itemLeft, itemRight] = this.getPanelSides(item);
        const [nearestTop, nearestLeft, nearestRight] = this.getPanelSides(nearest);
        const leftRight = this.getDistance(itemLeft, nearestRight);
        const rightLeft = this.getDistance(itemRight, nearestLeft); 
        const bottomTop = this.getDistance(itemBottom, nearestTop);

        const sides = [leftRight, rightLeft, bottomTop];
        const snapIndex = this.findSnapIndex(sides, sideIndex);
  
        if (snapIndex === 0 && leftRight <= SNAP_THRESHOLD) {
          return verifySnap(item, nearestX + WIDTH * (isPanel ? 0.52 : 0.5), nearestY + HEIGHT * (isPanel ? 0.02 : 0));
        } else if (snapIndex === 1 && rightLeft <= SNAP_THRESHOLD) {
          return verifySnap(item, nearestX - WIDTH * (isPanel ? 0.52 : 0.5), nearestY + HEIGHT * (isPanel ? 0.02 : 0));
        } else if (snapIndex === 2 && bottomTop <= SNAP_THRESHOLD) {
          return verifySnap(item, nearestX, nearestY - HEIGHT);
        }
      }
      return false;
    },
    rotateCanvasLinkers(index, newRotation) {
      const { x, y, isSnapped } = this.positions.canvas[index];
      this.setPosition({ type: 'canvas', index, x, y, rotation: newRotation, isSnapped });
      this.onDragStop('canvas', index, null, null, true);
    },
    async verifySnapCanvases(item, snapX, snapY) {
      // Do not allow snapping if it would place a canvas on top of another one
      for (const canvas of this.wallElement.getElementsByClassName('canvas')) {
        const { index } = this.getTypeAndIndexFromItem(canvas);
        const { x, y, isSnapped } = this.positions.canvas[index];
        if (item !== canvas && isSnapped) {
          const distanceBetweenMidpoints = this.getDistance([snapX, snapY], [x, y]);
          if (distanceBetweenMidpoints < this.canvasWidth * 0.99) {
            return false;
          }
        }
      }
      const { index } = this.getTypeAndIndexFromItem(item);
      this.setPosition({ type: 'canvas', index, x: snapX, y: snapY, rotation: this.positions.canvas[index].rotation });
      await this.$nextTick();
      return true;
    },
    snapCanvases(item, nearest, sideIndex) {
      const [itemLeft, itemTop, itemRight, itemBottom] = this.getCanvasSides(item);
      const [nearestLeft, nearestTop, nearestRight, nearestBottom] = this.getCanvasSides(nearest);

      const snapLeftRight = this.getDistance(itemLeft, nearestRight);
      const snapRightLeft = this.getDistance(itemRight, nearestLeft);
      const snapTopBottom = this.getDistance(itemTop, nearestBottom);
      const snapBottomTop = this.getDistance(itemBottom, nearestTop);

      const sides = [snapLeftRight, snapRightLeft, snapTopBottom, snapBottomTop];
      const snapIndex = this.findSnapIndex(sides, sideIndex);

      const { index: itemIndex } = this.getTypeAndIndexFromItem(item);
      const { type, index: nearestIndex } = this.getTypeAndIndexFromItem(nearest);
      const { x: nearestX, y: nearestY } = this.positions[type][nearestIndex];

      const { rotation: itemRotation } = this.positions.canvas[itemIndex];
      const { rotation: nearestRotation } = this.positions.canvas[nearestIndex];

      const SNAP_THRESHOLD = Number.POSITIVE_INFINITY;
      if (snapIndex === 0 && snapLeftRight <= SNAP_THRESHOLD) {
        if (itemRotation === 0) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY + this.canvasWidth * 0.5);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY + this.canvasWidth * 0.5);
          }
        } else if (itemRotation === 90) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY + this.canvasWidth * 0.5);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY + this.canvasWidth * 0.5);
          }
        } else if (itemRotation === 180) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY - this.canvasWidth * 0.5);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY - this.canvasWidth * 0.5);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY);
          }
        } else if (itemRotation === 270) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY - this.canvasWidth * 0.5);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY - this.canvasWidth * 0.5);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth, nearestY);
          }
        }
      } else if (snapIndex === 1 && snapRightLeft <= SNAP_THRESHOLD) {
        if (itemRotation === 0) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY + this.canvasWidth * 0.5);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY + this.canvasWidth * 0.5);
          }
        } else if (itemRotation === 90) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY + this.canvasWidth * 0.5);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY + this.canvasWidth * 0.5);
          }
        } else if (itemRotation === 180) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY - this.canvasWidth * 0.5);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY - this.canvasWidth * 0.5);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY);
          }
        } else if (itemRotation === 270) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY - this.canvasWidth * 0.5);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY - this.canvasWidth * 0.5);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth, nearestY);
          }
        }
      } else if (snapIndex === 2 && snapTopBottom <= SNAP_THRESHOLD) {
        if (itemRotation === 0) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX, nearestY + this.canvasWidth);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth * 0.5, nearestY + this.canvasWidth);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth * 0.5, nearestY + this.canvasWidth);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX, nearestY + this.canvasWidth);
          }
        } else if (itemRotation === 90) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth * 0.5, nearestY + this.canvasWidth);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX, nearestY + this.canvasWidth);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX, nearestY + this.canvasWidth);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth * 0.5, nearestY + this.canvasWidth);
          }
        } else if (itemRotation === 180) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth * 0.5, nearestY + this.canvasWidth);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX, nearestY + this.canvasWidth);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX, nearestY + this.canvasWidth);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth * 0.5, nearestY + this.canvasWidth);
          }
        } else if (itemRotation === 270) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX, nearestY + this.canvasWidth);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth * 0.5, nearestY + this.canvasWidth);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth * 0.5, nearestY + this.canvasWidth);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX, nearestY + this.canvasWidth);
          }
        }
      } else if (snapIndex === 3 && snapBottomTop <= SNAP_THRESHOLD) {
        if (itemRotation === 0) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX, nearestY - this.canvasWidth);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth * 0.5, nearestY - this.canvasWidth);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth * 0.5, nearestY - this.canvasWidth);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX, nearestY - this.canvasWidth);
          }
        } else if (itemRotation === 90) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth * 0.5, nearestY - this.canvasWidth);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX, nearestY - this.canvasWidth);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX, nearestY - this.canvasWidth);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth * 0.5, nearestY - this.canvasWidth);
          }
        } else if (itemRotation === 180) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth * 0.5, nearestY - this.canvasWidth);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX, nearestY - this.canvasWidth);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX, nearestY - this.canvasWidth);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX - this.canvasWidth * 0.5, nearestY - this.canvasWidth);
          }
        } else if (itemRotation === 270) {
          if (nearestRotation === 0) {
            return this.verifySnapCanvases(item, nearestX, nearestY - this.canvasWidth);
          } else if (nearestRotation === 90) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth * 0.5, nearestY - this.canvasWidth);
          } else if (nearestRotation === 180) {
            return this.verifySnapCanvases(item, nearestX + this.canvasWidth * 0.5, nearestY - this.canvasWidth);
          } else if (nearestRotation === 270) {
            return this.verifySnapCanvases(item, nearestX, nearestY - this.canvasWidth);
          }
        }
      }
      return false;
    },
    async verifySnapShapes(item, snapX, snapY) {
      // Do not allow snapping if it would place a shape on top of another one
      const { type, index } = this.getTypeAndIndexFromItem(item);
      const itemIsHexagon = type === 'hexagon';
      const itemIsTriangle = type === 'triangle';

      for (const shape of this.wallElement.getElementsByClassName('shapes')) {
        const { type, index } = this.getTypeAndIndexFromItem(shape);
        const { x, y, isSnapped } = this.positions[type][index];
        if (item !== shape && isSnapped) {
          const shapeIsHexagon = shape.className.includes('hexagon');
          const shapeIsTriangle = shape.className.includes('triangle');
          const distanceBetweenMidpoints = this.getDistance([snapX, snapY], [x, y]);

          if (itemIsHexagon && shapeIsHexagon && distanceBetweenMidpoints < this.hexagonWidth * 0.8) {
            return false;
          } else if (itemIsHexagon && shapeIsTriangle && distanceBetweenMidpoints < this.hexagonWidth * 0.5) {
            return false;
          } else if (itemIsHexagon && distanceBetweenMidpoints < this.hexagonWidth * 0.5) {
            return false;
          } else if (itemIsTriangle && shapeIsHexagon && distanceBetweenMidpoints < this.hexagonWidth * 0.5) {
            return false;
          } else if (itemIsTriangle && shapeIsTriangle) {
            if (this.isRotated(item) === this.isRotated(shape) && distanceBetweenMidpoints < this.triangleWidth * 0.55) {
              return false;
            } else if (distanceBetweenMidpoints < this.triangleWidth * 0.25) {
              return false;
            }
          } else if (itemIsTriangle && distanceBetweenMidpoints < this.triangleWidth * 0.5 && this.isRotated(item) === this.isRotated(shape)) {
            return false;
          } else if (shapeIsHexagon && distanceBetweenMidpoints < this.hexagonWidth * 0.5) {
            return false;
          } else if (shapeIsTriangle && distanceBetweenMidpoints < this.triangleWidth * 0.5 && this.isRotated(item) === this.isRotated(shape)) {
            return false;
          } else if (distanceBetweenMidpoints < this.miniTriangleWidth * 0.49) {
            return false;
          }
        }
      }
      this.setPosition({ type, index, x: snapX, y: snapY, rotation: this.positions[type][index].rotation });
      await this.$nextTick();
      return true;
    },
    // check if midpoint of a hexagon overlaps one of the corners of a triangle
    async verifyHexagonTriangle(self) {
      this.rotateItems();
      await this.$nextTick();
      const { type: selfType } = this.getTypeAndIndexFromItem(self);

      const triangles = Array.from(this.itemElements).filter((item) => this.getTypeAndIndexFromItem(item).type === 'triangle');
      if (selfType === 'hexagon') {
        const hexMid = this.getMidpoint(self);
        for (const triangle of triangles) {
          const handles = (this.isRotated(triangle) ? ['tl', 'tr'] : ['bl', 'br'])
            .map((handle) => triangle.getElementsByClassName(`handle-${handle}`)[0].getBoundingClientRect())
            .map((rect) => [rect.left, rect.top]);
          for (const handle of handles) {
            if (this.getDistance(hexMid, handle) < this.hexagonWidth * 0.1) {
              return false;
            }
          }
        }
      } else if (selfType == 'triangle') {
        const hexagons = Array.from(this.itemElements).filter((item) => this.getTypeAndIndexFromItem(item).type === 'hexagon');
        const handles = (this.isRotated(self) ? ['tl', 'tr'] : ['bl', 'br'])
          .map((handle) => self.getElementsByClassName(`handle-${handle}`)[0].getBoundingClientRect())
          .map((rect) => [rect.left, rect.top]);
        for (const hexagon of hexagons) {
          const hexMid = this.getMidpoint(hexagon);
          for (const handle of handles) {
            if (this.getDistance(hexMid, handle) < this.hexagonWidth * 0.1) {
              return false;
            }
          }
        }

        for (const triangle of triangles) {
          if (triangle === self) continue;
          const triMid = this.getMidpoint(triangle);
          for (const handle of handles) {
            if (this.getDistance(triMid, handle) < this.triangleWidth * 0.25) {
              return false;
            }
          }
        }
      }

      return true;
    },
    snapHexagons(item, nearest, sideIndex) {
      const [itemTop, itemLeftTop, itemLeftBottom, itemBottom, itemRightBottom, itemRightTop] = this.getHexagonSides(item);
      const [nearestTop, nearestLeftTop, nearestLeftBottom, nearestBottom, nearestRightBottom, nearestRightTop] = this.getHexagonSides(nearest);

      const topBottom = this.getDistance(itemTop, nearestBottom);
      const leftTopRightBottom = this.getDistance(itemLeftTop, nearestRightBottom); 
      const leftBottomRightTop = this.getDistance(itemLeftBottom, nearestRightTop);
      const bottomTop = this.getDistance(itemBottom, nearestTop);
      const rightBottomLeftTop = this.getDistance(itemRightBottom, nearestLeftTop); 
      const rightTopLeftBottom = this.getDistance(itemRightTop, nearestLeftBottom);

      const sides = [topBottom, leftTopRightBottom, leftBottomRightTop, bottomTop, rightBottomLeftTop, rightTopLeftBottom];
      const snapIndex = this.findSnapIndex(sides, sideIndex);

      const { type, index: nearestIndex } = this.getTypeAndIndexFromItem(nearest);
      const { x: nearestX, y: nearestY } = this.positions[type][nearestIndex];

      const SNAP_THRESHOLD = Number.POSITIVE_INFINITY;
      if (snapIndex === 0 && topBottom <= SNAP_THRESHOLD) {
        return this.verifySnapShapes(item, nearestX, nearestY + this.hexagonHeight);
      } else if (snapIndex === 1 && leftTopRightBottom <= SNAP_THRESHOLD) {
        return this.verifySnapShapes(item, nearestX + this.hexagonWidth * 0.75, nearestY + this.hexagonHeight * 0.5);
      } else if (snapIndex === 2 && leftBottomRightTop <= SNAP_THRESHOLD) {
        return this.verifySnapShapes(item, nearestX + this.hexagonWidth * 0.75, nearestY - this.hexagonHeight * 0.5);
      } else if (snapIndex === 3 && bottomTop <= SNAP_THRESHOLD) {
        return this.verifySnapShapes(item, nearestX, nearestY - this.hexagonHeight);
      } else if (snapIndex === 4 && rightBottomLeftTop <= SNAP_THRESHOLD) {
        return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.75, nearestY - this.hexagonHeight * 0.5);
      } else if (snapIndex === 5 && rightTopLeftBottom <= SNAP_THRESHOLD) {
        return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.75, nearestY + this.hexagonHeight * 0.5);
      }
      return false;
    },
    snapHexagonToTriangle(item, nearest, sideIndex) {
      const [itemTop, itemLeftTop, itemLeftBottom, itemBottom, itemRightBottom, itemRightTop] = this.getHexagonSides(item);

      const SNAP_THRESHOLD = Number.POSITIVE_INFINITY;
      const { type, index: nearestIndex } = this.getTypeAndIndexFromItem(nearest);
      const { x: nearestX, y: nearestY } = this.positions[type][nearestIndex];
      if (this.isRotated(nearest)) {
        const [nearestTopLeft, nearestTopRight, nearestLeftTop, nearestLeftBottom, nearestRightTop, nearestRightBottom] = this.getTriangleSides(nearest);
        const leftTopRightTop = this.getDistance(itemLeftTop, nearestRightTop);
        const leftTopRightBottom = this.getDistance(itemLeftTop, nearestRightBottom);
        const rightTopLeftTop = this.getDistance(itemRightTop, nearestLeftTop);
        const rightTopLeftBottom = this.getDistance(itemRightTop, nearestLeftBottom);
        const bottomTopLeft = this.getDistance(itemBottom, nearestTopLeft);
        const bottomTopRight = this.getDistance(itemBottom, nearestTopRight);

        const sides = [leftTopRightTop, leftTopRightBottom, rightTopLeftTop, rightTopLeftBottom, bottomTopLeft, bottomTopRight];
        const snapIndex = this.findSnapIndex(sides, sideIndex);

        if (snapIndex === 0 && leftTopRightTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.75, nearestY);
        } else if (snapIndex === 1 && leftTopRightBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.5, nearestY + this.triangleHeight * 0.5);
        } else if (snapIndex === 2 && rightTopLeftTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.75, nearestY);
        } else if (snapIndex === 3 && rightTopLeftBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.5, nearestY + this.triangleHeight * 0.5);
        } else if (snapIndex === 4 && bottomTopLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.25, nearestY - this.hexagonHeight);
        } else if (snapIndex === 5 && bottomTopRight <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.25, nearestY - this.hexagonHeight);
        }
      } else {
        const [nearestBottomLeft, nearestBottomRight, nearestLeftBottom, nearestLeftTop, nearestRightBottom, nearestRightTop] = this.getTriangleSides(nearest);
        const leftBottomRightTop = this.getDistance(itemLeftBottom, nearestRightTop);
        const leftBottomRightBottom = this.getDistance(itemLeftBottom, nearestRightBottom);
        const rightBottomLeftTop = this.getDistance(itemRightBottom, nearestLeftTop);
        const rightBottomLeftBottom = this.getDistance(itemRightBottom, nearestLeftBottom);
        const topBottomLeft = this.getDistance(itemTop, nearestBottomLeft);
        const topBottomRight = this.getDistance(itemTop, nearestBottomRight);

        const sides = [leftBottomRightTop, leftBottomRightBottom, rightBottomLeftTop, rightBottomLeftBottom, topBottomLeft, topBottomRight];
        const snapIndex = this.findSnapIndex(sides, sideIndex);

        if (snapIndex === 0 && leftBottomRightTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.5, nearestY - this.hexagonHeight * 0.5);
        } else if (snapIndex === 1 && leftBottomRightBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.75, nearestY);
        } else if (snapIndex === 2 && rightBottomLeftTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.5, nearestY - this.hexagonHeight * 0.5);
        } else if (snapIndex === 3 && rightBottomLeftBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.75, nearestY);
        } else if (snapIndex === 4 && topBottomLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.25, nearestY + this.triangleHeight);
        } else if (snapIndex === 5 && topBottomRight <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.hexagonWidth * 0.25, nearestY + this.triangleHeight);
        }
      }
      return false;
    },
    snapHexagonToMiniTriangle(item, nearest, sideIndex) {
      const [itemTop, itemLeftTop, itemLeftBottom, itemBottom, itemRightBottom, itemRightTop] = this.getHexagonSides(item);

      const SNAP_THRESHOLD = Number.POSITIVE_INFINITY;
      const { type, index: nearestIndex } = this.getTypeAndIndexFromItem(nearest);
      const { x: nearestX, y: nearestY } = this.positions[type][nearestIndex];
      if (this.isRotated(nearest)) {
        const [nearestTop, nearestLeft, nearestRight] = this.getPanelSides(nearest);
        const leftTopRight = this.getDistance(itemLeftTop, nearestRight);
        const rightTopLeft = this.getDistance(itemRightTop, nearestLeft);
        const bottomTop = this.getDistance(itemBottom, nearestTop);

        const sides = [leftTopRight, rightTopLeft, bottomTop];
        const snapIndex = this.findSnapIndex(sides, sideIndex);
        
        if (snapIndex === 0 && leftTopRight <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.hexagonWidth * 0.5, nearestY + this.hexagonHeight * 0.25);
        } else if (snapIndex === 1 && rightTopLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.5, nearestY + this.hexagonHeight * 0.25);
        } else if (snapIndex === 2 && bottomTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX, nearestY - this.hexagonHeight * 0.75);
        }
      } else {
        const [nearestBottom, nearestLeft, nearestRight] = this.getPanelSides(nearest);
        const leftBottomRight = this.getDistance(itemLeftBottom, nearestRight);
        const rightBottomLeft = this.getDistance(itemRightBottom, nearestLeft);
        const topBottom = this.getDistance(itemTop, nearestBottom);

        const sides = [leftBottomRight, rightBottomLeft, topBottom];
        const snapIndex = this.findSnapIndex(sides, sideIndex);

        if (snapIndex === 0 && leftBottomRight <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.hexagonWidth * 0.5, nearestY - this.hexagonHeight * 0.25);
        } else if (snapIndex === 1 && rightBottomLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.5, nearestY - this.hexagonHeight * 0.25);
        } else if (snapIndex === 2 && topBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX, nearestY + this.hexagonHeight * 0.75);
        }
      }
      return false;
    },
    snapTriangleToHexagon(item, nearest, sideIndex) {
      const [nearestTop, nearestLeftTop, nearestLeftBottom, nearestBottom, nearestRightBottom, nearestRightTop] = this.getHexagonSides(nearest);

      const SNAP_THRESHOLD = Number.POSITIVE_INFINITY;
      const ROTATE_THRESHOLD = this.triangleWidth * 0.25;
      if (this.isRotated(item)) {
        const [, , itemLeftTop, itemLeftBottom, itemRightTop, itemRightBottom] = this.getTriangleSides(item);
        const leftTopRightBottom = this.getDistance(itemLeftTop, nearestRightBottom);
        const rightTopLeftBottom = this.getDistance(itemRightTop, nearestLeftBottom);
        const leftBottomTop = this.getDistance(itemLeftBottom, nearestTop);
        const rightBottomTop = this.getDistance(itemRightBottom, nearestTop);

        if (leftTopRightBottom <= ROTATE_THRESHOLD) this.rotate(item);
        else if (rightTopLeftBottom <= ROTATE_THRESHOLD) this.rotate(item);
        else if (leftBottomTop <= ROTATE_THRESHOLD) this.rotate(item);
        else if (rightBottomTop <= ROTATE_THRESHOLD) this.rotate(item);
      } else {
        const [, , itemLeftBottom, itemLeftTop, itemRightBottom, itemRightTop] = this.getTriangleSides(item);
        const leftBottomRightTop = this.getDistance(itemLeftBottom, nearestRightTop);
        const rightBottomLeftTop = this.getDistance(itemRightBottom, nearestLeftTop);
        const leftTopBottom = this.getDistance(itemLeftTop, nearestBottom);
        const rightTopBottom = this.getDistance(itemRightTop, nearestBottom);

        if (leftBottomRightTop <= ROTATE_THRESHOLD) this.rotate(item);
        else if (rightBottomLeftTop <= ROTATE_THRESHOLD) this.rotate(item);
        else if (leftTopBottom <= ROTATE_THRESHOLD) this.rotate(item);
        else if (rightTopBottom <= ROTATE_THRESHOLD) this.rotate(item);
      }

      const { type, index: nearestIndex } = this.getTypeAndIndexFromItem(nearest);
      const { x: nearestX, y: nearestY } = this.positions[type][nearestIndex];
      if (this.isRotated(item)) {
        const [itemTopLeft, itemTopRight, itemLeftTop, itemLeftBottom, itemRightTop, itemRightBottom] = this.getTriangleSides(item);
        const leftTopRightTop = this.getDistance(itemLeftTop, nearestRightTop);
        const leftBottomRightTop = this.getDistance(itemLeftBottom, nearestRightTop);
        const rightTopLeftTop = this.getDistance(itemRightTop, nearestLeftTop);
        const rightBottomLeftTop = this.getDistance(itemRightBottom, nearestLeftTop);
        const topLeftBottom = this.getDistance(itemTopLeft, nearestBottom);
        const topRightBottom = this.getDistance(itemTopRight, nearestBottom);

        const sides = [leftTopRightTop, leftBottomRightTop, rightTopLeftTop, rightBottomLeftTop, topLeftBottom, topRightBottom];
        const snapIndex = this.findSnapIndex(sides, sideIndex);

        if (snapIndex === 0 && leftTopRightTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.hexagonWidth * 0.75, nearestY);
        } else if (snapIndex === 1 && leftBottomRightTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.hexagonWidth * 0.5, nearestY - this.triangleHeight * 0.5);
        } else if (snapIndex === 2 && rightTopLeftTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.75, nearestY);
        } else if (snapIndex === 3 && rightBottomLeftTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.5, nearestY - this.triangleHeight * 0.5);
        } else if (snapIndex === 4 && topLeftBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.hexagonWidth * 0.25, nearestY + this.hexagonHeight);
        } else if (snapIndex === 5 && topRightBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.25, nearestY + this.hexagonHeight);
        }
      } else {
        const [itemBottomLeft, itemBottomRight, itemLeftBottom, itemLeftTop, itemRightBottom, itemRightTop] = this.getTriangleSides(item);
        const leftTopRightBottom = this.getDistance(itemLeftTop, nearestRightBottom);
        const leftBottomRightBottom = this.getDistance(itemLeftBottom, nearestRightBottom);
        const rightBottomLeftBottom = this.getDistance(itemRightBottom, nearestLeftBottom);
        const rightTopLeftBottom = this.getDistance(itemRightTop, nearestLeftBottom);
        const bottomLeftTop = this.getDistance(itemBottomLeft, nearestTop);
        const bottomRightTop = this.getDistance(itemBottomRight, nearestTop);

        const sides = [leftTopRightBottom, leftBottomRightBottom, rightBottomLeftBottom, rightTopLeftBottom, bottomLeftTop, bottomRightTop];
        const snapIndex = this.findSnapIndex(sides, sideIndex);

        if (snapIndex === 0 && leftTopRightBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.hexagonWidth * 0.5, nearestY + this.hexagonHeight * 0.5);
        } else if (snapIndex === 1 && leftBottomRightBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.hexagonWidth * 0.75, nearestY);
        } else if (snapIndex === 2 && rightBottomLeftBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.75, nearestY);
        } else if (snapIndex === 3 && rightTopLeftBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.5, nearestY + this.hexagonHeight * 0.5);
        } else if (snapIndex === 4 && bottomLeftTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.hexagonWidth * 0.25, nearestY - this.triangleHeight);
        } else if (snapIndex === 5 && bottomRightTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.25, nearestY - this.triangleHeight);
        }
      }
      return false;
    },
    snapTriangles(item, nearest, sideIndex) {
      if (this.isRotated(item) === this.isRotated(nearest)) this.rotate(item);

      const SNAP_THRESHOLD = Number.POSITIVE_INFINITY;
      const { type, index: nearestIndex } = this.getTypeAndIndexFromItem(nearest);
      const { x: nearestX, y: nearestY } = this.positions[type][nearestIndex];

      if (this.isRotated(item)) {
        const [itemTopLeft, itemTopRight, itemLeftTop, itemLeftBottom, itemRightTop, itemRightBottom] = this.getTriangleSides(item);
        const [nearestBottomLeft, nearestBottomRight, nearestLeftBottom, nearestLeftTop, nearestRightBottom, nearestRightTop] = this.getTriangleSides(nearest);
        const leftTopRightTop = this.getDistance(itemLeftTop, nearestRightTop);
        const rightTopLeftTop = this.getDistance(itemRightTop, nearestLeftTop);
        const topLeftBottomLeft = this.getDistance(itemTopLeft, nearestBottomLeft);
        const leftTopRightBottom = this.getDistance(itemLeftTop, nearestRightBottom);
        const rightTopLeftBottom = this.getDistance(itemRightTop, nearestLeftBottom);
        const leftBottomRightTop = this.getDistance(itemLeftBottom, nearestRightTop);
        const rightBottomLeftTop = this.getDistance(itemRightBottom, nearestLeftTop);
        const topLeftBottomRight = this.getDistance(itemTopLeft, nearestBottomRight);
        const topRightBottomLeft = this.getDistance(itemTopRight, nearestBottomLeft);

        const sides = [leftTopRightTop, rightTopLeftTop, topLeftBottomLeft, leftTopRightBottom, rightTopLeftBottom, leftBottomRightTop, rightBottomLeftTop, topLeftBottomRight, topRightBottomLeft];
        const snapIndex = this.findSnapIndex(sides, sideIndex);

        if (snapIndex === 0 && leftTopRightTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.5, nearestY);
        } else if (snapIndex === 1 && rightTopLeftTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.5, nearestY);
        } else if (snapIndex === 2 && topLeftBottomLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX, nearestY + this.triangleHeight);
        } else if (snapIndex === 3 && leftTopRightBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.75, nearestY + this.triangleHeight * 0.5);
        } else if (snapIndex === 4 && rightTopLeftBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.75, nearestY + this.triangleHeight * 0.5);
        } else if (snapIndex === 5 && leftBottomRightTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.25, nearestY - this.triangleHeight * 0.5);
        } else if (snapIndex === 6 && rightBottomLeftTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.25, nearestY - this.triangleHeight * 0.5);
        } else if (snapIndex === 7 && topLeftBottomRight <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.5, nearestY + this.triangleHeight);
        } else if (snapIndex === 8 && topRightBottomLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.5, nearestY + this.triangleHeight);
        }
      } else {
        const [itemBottomLeft, itemBottomRight, itemLeftBottom, itemLeftTop, itemRightBottom, itemRightTop] = this.getTriangleSides(item);
        const [nearestTopLeft, nearestTopRight, nearestLeftTop, nearestLeftBottom, nearestRightTop, nearestRightBottom] = this.getTriangleSides(nearest);
        const leftBottomRightBottom = this.getDistance(itemLeftBottom, nearestRightBottom);
        const rightBottomLeftBottom = this.getDistance(itemRightBottom, nearestLeftBottom);
        const bottomLeftTopLeft = this.getDistance(itemBottomLeft, nearestTopLeft);
        const leftBottomRightTop = this.getDistance(itemLeftBottom, nearestRightTop);
        const rightBottomLeftTop = this.getDistance(itemRightBottom, nearestLeftTop);
        const leftTopRightBottom = this.getDistance(itemLeftTop, nearestRightBottom);
        const rightTopLeftBottom = this.getDistance(itemRightTop, nearestLeftBottom);
        const bottomLeftTopRight = this.getDistance(itemBottomLeft, nearestTopRight);
        const bottomRightTopLeft = this.getDistance(itemBottomRight, nearestTopLeft);

        const sides = [leftBottomRightBottom, rightBottomLeftBottom, bottomLeftTopLeft, leftBottomRightTop, rightBottomLeftTop, leftTopRightBottom, rightTopLeftBottom, bottomLeftTopRight, bottomRightTopLeft];
        const snapIndex = this.findSnapIndex(sides, sideIndex);

        if (snapIndex === 0 && leftBottomRightBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.5, nearestY);
        } else if (snapIndex === 1 && rightBottomLeftBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.5, nearestY);
        } else if (snapIndex === 2 && bottomLeftTopLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX, nearestY - this.triangleHeight);
        } else if (snapIndex === 3 && leftBottomRightTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.75, nearestY - this.triangleHeight * 0.5);
        } else if (snapIndex === 4 && rightBottomLeftTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.75, nearestY - this.triangleHeight * 0.5);
        } else if (snapIndex === 5 && leftTopRightBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.25, nearestY + this.triangleHeight * 0.5);
        } else if (snapIndex === 6 && rightTopLeftBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.25, nearestY + this.triangleHeight * 0.5);
        } else if (snapIndex === 7 && bottomLeftTopRight <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.5, nearestY - this.triangleHeight);
        } else if (snapIndex === 8 && bottomRightTopLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.5, nearestY - this.triangleHeight);
        }
      }
      return false;
    },
    snapTriangleToMiniTriangle(item, nearest, sideIndex) {
      if (this.isRotated(item) === this.isRotated(nearest)) this.rotate(item);

      const SNAP_THRESHOLD = Number.POSITIVE_INFINITY;
      const { type, index: nearestIndex } = this.getTypeAndIndexFromItem(nearest);
      const { x: nearestX, y: nearestY } = this.positions[type][nearestIndex];
      if (this.isRotated(item)) {
        const [itemTopLeft, itemTopRight, itemLeftTop, itemLeftBottom, itemRightTop, itemRightBottom] = this.getTriangleSides(item);
        const [nearestBottom, nearestLeft, nearestRight] = this.getPanelSides(nearest);
        const leftTopRight = this.getDistance(itemLeftTop, nearestRight);
        const leftBottomRight = this.getDistance(itemLeftBottom, nearestRight);
        const rightTopLeft = this.getDistance(itemRightTop, nearestLeft);
        const rightBottomLeft = this.getDistance(itemRightBottom, nearestLeft);
        const topRightBottom = this.getDistance(itemTopRight, nearestBottom);
        const topLeftBottom = this.getDistance(itemTopLeft, nearestBottom);

        const sides = [leftTopRight, leftBottomRight, rightTopLeft, rightBottomLeft, topRightBottom, topLeftBottom];
        const snapIndex = this.findSnapIndex(sides, sideIndex);

        if (snapIndex === 0 && leftTopRight <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.miniTriangleWidth, nearestY + this.miniTriangleHeight * 0.5);
        } else if (snapIndex === 1 && leftBottomRight <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.miniTriangleWidth * 0.5, nearestY - this.miniTriangleHeight * 0.5);
        } else if (snapIndex === 2 && rightTopLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.miniTriangleWidth, nearestY + this.miniTriangleHeight * 0.5);
        } else if (snapIndex === 3 && rightBottomLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.miniTriangleWidth * 0.5, nearestY - this.miniTriangleHeight * 0.5);
        } else if (snapIndex === 4 && topRightBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.miniTriangleWidth * 0.5, nearestY + this.miniTriangleHeight * 1.5);
        } else if (snapIndex === 5 && topLeftBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.miniTriangleWidth * 0.5, nearestY + this.miniTriangleHeight * 1.5);
        }
      } else {
        const [itemBottomLeft, itemBottomRight, itemLeftBottom, itemLeftTop, itemRightBottom, itemRightTop] = this.getTriangleSides(item);
        const [nearestTop, nearestLeft, nearestRight] = this.getPanelSides(nearest);
        const leftTopRight = this.getDistance(itemLeftTop, nearestRight);
        const leftBottomRight = this.getDistance(itemLeftBottom, nearestRight);
        const rightTopLeft = this.getDistance(itemRightTop, nearestLeft);
        const rightBottomLeft = this.getDistance(itemRightBottom, nearestLeft);
        const bottomLeftTop = this.getDistance(itemBottomLeft, nearestTop);
        const bottomRightTop = this.getDistance(itemBottomRight, nearestTop);

        const sides = [leftTopRight, leftBottomRight, rightTopLeft, rightBottomLeft, bottomLeftTop, bottomRightTop];
        const snapIndex = this.findSnapIndex(sides, sideIndex);

        if (snapIndex === 0 && leftTopRight <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.miniTriangleWidth * 0.5, nearestY + this.miniTriangleHeight * 0.5);
        } else if (snapIndex === 1 && leftBottomRight <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.miniTriangleWidth, nearestY - this.miniTriangleHeight * 0.5);
        } else if (snapIndex === 2 && rightTopLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.miniTriangleWidth * 0.5, nearestY + this.miniTriangleHeight * 0.5);
        } else if (snapIndex === 3 && rightBottomLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.miniTriangleWidth, nearestY - this.miniTriangleHeight * 0.5);
        } else if (snapIndex === 4 && bottomLeftTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.miniTriangleWidth * 0.5, nearestY - this.miniTriangleHeight * 1.5);
        } else if (snapIndex === 5 && bottomRightTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.miniTriangleWidth * 0.5, nearestY - this.miniTriangleHeight * 1.5);
        }
      }
      return false;
    },
    snapMiniTriangleToHexagon(item, nearest, sideIndex) {
      const [nearestTop, nearestLeftTop, nearestLeftBottom, nearestBottom, nearestRightBottom, nearestRightTop] = this.getHexagonSides(nearest);

      const SNAP_THRESHOLD = Number.POSITIVE_INFINITY;
      const ROTATE_THRESHOLD = this.miniTriangleWidth * 0.5;
      if (this.isRotated(item)) {
        const [, itemLeft, itemRight] = this.getPanelSides(item);
        const leftRightBottom = this.getDistance(itemLeft, nearestRightBottom);
        const rightLeftBottom = this.getDistance(itemRight, nearestLeftBottom);
        const leftTop = this.getDistance(itemLeft, nearestTop);
        const rightTop = this.getDistance(itemRight, nearestTop);

        if (leftRightBottom <= ROTATE_THRESHOLD) this.rotate(item);
        else if (rightLeftBottom <= ROTATE_THRESHOLD) this.rotate(item);
        else if (leftTop <= ROTATE_THRESHOLD) this.rotate(item);
        else if (rightTop <= ROTATE_THRESHOLD) this.rotate(item);
      } else {
        const [, itemLeft, itemRight] = this.getPanelSides(item);
        const leftRightTop = this.getDistance(itemLeft, nearestRightTop);
        const rightLeftTop = this.getDistance(itemRight, nearestLeftTop);
        const leftBottom = this.getDistance(itemLeft, nearestBottom);
        const rightBottom = this.getDistance(itemRight, nearestBottom);

        if (leftRightTop <= ROTATE_THRESHOLD) this.rotate(item);
        else if (rightLeftTop <= ROTATE_THRESHOLD) this.rotate(item);
        else if (leftBottom <= ROTATE_THRESHOLD) this.rotate(item);
        else if (rightBottom <= ROTATE_THRESHOLD) this.rotate(item);
      }

      const { type, index: nearestIndex } = this.getTypeAndIndexFromItem(nearest);
      const { x: nearestX, y: nearestY } = this.positions[type][nearestIndex];
      if (this.isRotated(item)) {
        const [itemTop, itemLeft, itemRight] = this.getPanelSides(item);
        const rightLeftTop = this.getDistance(itemRight, nearestLeftTop);
        const leftRightTop = this.getDistance(itemLeft, nearestRightTop);
        const topBottom = this.getDistance(itemTop, nearestBottom);

        const sides = [rightLeftTop, leftRightTop, topBottom];
        const snapIndex = this.findSnapIndex(sides, sideIndex);

        if (snapIndex === 0 && rightLeftTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.5, nearestY - this.hexagonHeight * 0.25);
        } else if (snapIndex === 1 && leftRightTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.hexagonWidth * 0.5, nearestY - this.hexagonHeight * 0.25);
        } else if (snapIndex === 2 && topBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX, nearestY + this.hexagonHeight * 0.75);
        }
      } else {
        const [itemBottom, itemLeft, itemRight] = this.getPanelSides(item);
        const leftRightBottom = this.getDistance(itemLeft, nearestRightBottom);
        const rightLeftBottom = this.getDistance(itemRight, nearestLeftBottom);
        const bottomTop = this.getDistance(itemBottom, nearestTop);

        const sides = [leftRightBottom, rightLeftBottom, bottomTop];
        const snapIndex = this.findSnapIndex(sides, sideIndex);

        if (snapIndex === 0 && leftRightBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.hexagonWidth * 0.5, nearestY + this.hexagonHeight * 0.25);
        } else if (snapIndex === 1 && rightLeftBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.hexagonWidth * 0.5, nearestY + this.hexagonHeight * 0.25);
        } else if (snapIndex === 2 && bottomTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX, nearestY - this.hexagonHeight * 0.75);
        }
      }
      return false;
    },
    snapMiniTriangleToTriangle(item, nearest, sideIndex) {
      if (this.isRotated(item) === this.isRotated(nearest)) this.rotate(item);

      const SNAP_THRESHOLD = Number.POSITIVE_INFINITY;
      const { type, index: nearestIndex } = this.getTypeAndIndexFromItem(nearest);
      const { x: nearestX, y: nearestY } = this.positions[type][nearestIndex];
      if (this.isRotated(item)) {
        const [itemTop, itemLeft, itemRight] = this.getPanelSides(item);
        const [nearestBottomLeft, nearestBottomRight, nearestLeftBottom, nearestLeftTop, nearestRightBottom, nearestRightTop] = this.getTriangleSides(nearest);
        const leftRightTop = this.getDistance(itemLeft, nearestRightTop);
        const leftRightBottom = this.getDistance(itemLeft, nearestRightBottom);
        const rightLeftTop = this.getDistance(itemRight, nearestLeftTop);
        const rightLeftBottom = this.getDistance(itemRight, nearestLeftBottom);
        const topBottomLeft = this.getDistance(itemTop, nearestBottomLeft);
        const topBottomRight = this.getDistance(itemTop, nearestBottomRight);

        const sides = [leftRightTop, leftRightBottom, rightLeftTop, rightLeftBottom, topBottomLeft, topBottomRight];
        const snapIndex = this.findSnapIndex(sides, sideIndex);

        if (snapIndex === 0 && leftRightTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.25, nearestY - this.triangleHeight * 0.25);
        } else if (snapIndex === 1 && leftRightBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.5, nearestY + this.triangleHeight * 0.25);
        } else if (snapIndex === 2 && rightLeftTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.25, nearestY - this.triangleHeight * 0.25);
        } else if (snapIndex === 3 && rightLeftBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.5, nearestY + this.triangleHeight * 0.25);
        } else if (snapIndex === 4 && topBottomLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.25, nearestY + this.triangleHeight * 0.75);
        } else if (snapIndex === 5 && topBottomRight <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.25, nearestY + this.triangleHeight * 0.75);
        }
      } else {
        const [itemBottom, itemLeft, itemRight] = this.getPanelSides(item);
        const [nearestTopLeft, nearestTopRight, nearestLeftTop, nearestLeftBottom, nearestRightTop, nearestRightBottom] = this.getTriangleSides(nearest);
        const leftRightTop = this.getDistance(itemLeft, nearestRightTop);
        const leftRightBottom = this.getDistance(itemLeft, nearestRightBottom);
        const rightLeftTop = this.getDistance(itemRight, nearestLeftTop);
        const rightLeftBottom = this.getDistance(itemRight, nearestLeftBottom);
        const bottomTopLeft = this.getDistance(itemBottom, nearestTopLeft);
        const bottomTopRight = this.getDistance(itemBottom, nearestTopRight);

        const sides = [leftRightTop, leftRightBottom, rightLeftTop, rightLeftBottom, bottomTopLeft, bottomTopRight];
        const snapIndex = this.findSnapIndex(sides, sideIndex);

        if (snapIndex === 0 && leftRightTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.5, nearestY - this.triangleHeight * 0.25);
        } else if (snapIndex === 1 && leftRightBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.25, nearestY + this.triangleHeight * 0.25);
        } else if (snapIndex === 2 && rightLeftTop <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.5, nearestY - this.triangleHeight * 0.25);
        } else if (snapIndex === 3 && rightLeftBottom <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.25, nearestY + this.triangleHeight * 0.25);
        } else if (snapIndex === 4 && bottomTopLeft <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX - this.triangleWidth * 0.25, nearestY - this.triangleHeight * 0.75);
        } else if (snapIndex === 5 && bottomTopRight <= SNAP_THRESHOLD) {
          return this.verifySnapShapes(item, nearestX + this.triangleWidth * 0.25, nearestY - this.triangleHeight * 0.75);
        }
      }
      return false;
    },
    getMidpoint(item) {
      const { topLeft, bottomRight } = this.getItemCorners(item);
      return [(topLeft.left + bottomRight.left) * 0.5, (topLeft.top + bottomRight.top) * 0.5];
    },
    sidesSortedByDistance(self) {
      const [selfMidpoint] = this.adjustCoordsToWall([this.getMidpoint(self)]);
      const sides = [];
      for (const item of this.itemElements) {
        const { type, index } = this.getTypeAndIndexFromItem(item);
        if (item !== self && this.positions[type][index].isSnapped) {
          const itemSides = this.getSides(item).map((side) => ({
            item,
            coords: side,
            distance: this.getDistance(side, selfMidpoint),
          }));
          itemSides.sort((a, b) => a.distance - b.distance);
          for (let i = 0; i < itemSides.length; i++) {
            itemSides[i].index = i;
          }
          sides.push(...itemSides);
        }
      }
      sides.push({ item: { id: 'controller_0' }, coords: [this.controllerPosition.x, this.controllerPosition.y] })

      // remove sides that are close together (i.e. are already snapped)
      const sidesToRemove = new Set();
      for (const s1 of sides) {
        for (const s2 of sides) {
          if (s1 !== s2 && this.getDistance(s1.coords, s2.coords) < this.panelWidth * 0.04) {
            sidesToRemove.add(s1);
            sidesToRemove.add(s2);
          }
        }
      }
      return sides.filter((side) => !sidesToRemove.has(side)).sort((a, b) => a.distance - b.distance);
    },
    getLayoutMidpoint(useDummy = false) {
      const bounds = Array.from(useDummy ? this.dummyElements : this.itemElements).reduce((values, item) => {
        const { left, right, top, bottom } = item.getBoundingClientRect();
        return {
          left: Math.min(values.left, left),
          right: Math.max(values.right, right),
          top: Math.min(values.top, top),
          bottom: Math.max(values.bottom, bottom),
        };
      }, {
        left: Number.POSITIVE_INFINITY,
        right: Number.NEGATIVE_INFINITY,
        top: Number.POSITIVE_INFINITY,
        bottom: Number.NEGATIVE_INFINITY,
      });
      return { x: (bounds.left + bounds.right) / 2, y: (bounds.top + bounds.bottom) / 2 };
    },
    rotateItems() {
      const { x, y } = this.getLayoutMidpoint(true);
      Array.from(this.itemElements).forEach((item, i) => {
        const { left, top } = this.dummyElements[i].getBoundingClientRect();
        const origin = `${(x - left) / this.wallZoom}px ${(y - top) / this.wallZoom}px`;
        item.style.transformOrigin = origin;

        if (this.mountingTapeElements[i]) this.mountingTapeElements[i].style.transformOrigin = origin;
        if (this.activeModel === 'NL29' && this.mountingPlateElements[i]) this.mountingPlateElements[i].style.transformOrigin = origin;
        if (this.mountingElements[i]) this.mountingElements[i].style.transformOrigin = origin;
      });
    },
    async centreItems() {
      if (this.totalItemCount === 0) return;
      const { left, top, width, height } = this.wallElement.getBoundingClientRect();
      const wallMidX = left + width / 2;
      const wallMidY = top + height / 2;
      const { x: originX, y: originY } = this.getLayoutMidpoint();
      const dx = (wallMidX - originX) / this.wallZoom;
      const dy = (wallMidY - originY) / this.wallZoom;

      // batch all position updates together for performance
      const positions = Array.from(this.itemElements).map((item) => {
        const { type, index } = this.getTypeAndIndexFromItem(item);
        const position = this.positions[type][index];
        return {
          type,
          index,
          ...position,
          x: position.x + dx,
          y: position.y + dy,
        };
      });
      const allPositions = {};
      for (const t of ['panel', 'canvas', 'hexagon', 'triangle', 'miniTriangle']) {
        allPositions[t] = positions.filter(({ type }) => type === t)
          .sort((a, b) => a.index - b.index)
          .map(({ x, y, rotation, isSnapped }) => ({ x, y, rotation, isSnapped }));
      }
      this.setAllPositions(allPositions);
      this.setControllerPosition({
        x: this.controllerPosition.x + dx,
        y: this.controllerPosition.y + dy,
        rotation: this.controllerPosition.rotation,
      });
      await this.$nextTick();
    },
    // return the coordinates that need to be checked if they are outside the wall
    getAdjustedEdges(item) {
      const { type } = this.getTypeAndIndexFromItem(item);
      const [tl, tm, tr, bl, bm, br, lm, rm] = this.getAdjustedItemCornersAndMiddles(item);
      if (type === 'panel' || type === 'triangle' || type === 'miniTriangle') {
        if (this.isRotated(item)) {
          return [tl, tr, bm];
        } else {
          return [bl, br, tm];
        }
      } else if (type === 'canvas') {
        return [tl, tr, bl, br];
      } else if (type === 'hexagon') {
        return [tm, rm, bm, lm]; 
      } else {
        return [];
      }
    },
    highlightItemsOutsideWall() {
      this.itemsOutsideWall.clear();
      const { clientHeight: wallHeight, clientWidth: wallWidth } = this.wallElement;
      let showMessage = false;
      for (const item of this.itemElements) {
        const sides = this.getAdjustedEdges(item);
        const xCoords = sides.map((side) => side[0]);
        const yCoords = sides.map((side) => side[1]);
        const [path] = item.getElementsByClassName('border');
        if (xCoords.some((x) => x < 0 || x > wallWidth) || yCoords.some((y) => y < 0 || y > wallHeight)) {
          this.itemsOutsideWall.add(item.id);
          path.style.stroke = '#FF0000';
          showMessage = true;
        } else {
          this.itemsOutsideWall.delete(item.id);
          if (!this.disconnectedItems.has(item.id)) {
            path.style.stroke = '#F1F2F2';
          }
        }
      }

      function waitForInvalidLayoutTooltip() {
        if (this.invalidLayoutTooltipOpen) {
          setTimeout(waitForInvalidLayoutTooltip.bind(this), 100);
        } else {
          this.overflowTooltipOpen = showMessage;
        }
      }
      if (this.invalidLayoutTooltipOpen && showMessage) {
        waitForInvalidLayoutTooltip.call(this);
      } else {
        this.overflowTooltipOpen = showMessage;
      }
    },
    // current code does not use actual canvas sides for snapping so we need to get the corners and middles
    getAdjustedItemCornersAndMiddles(item) {
      const { topLeft, topRight, bottomLeft, bottomRight } = this.itemCornersAsCoords(item);
      const tl = [topLeft[0], topLeft[1]];
      const tm = [topLeft[0] * 0.5 + topRight[0] * 0.5, topLeft[1] * 0.5 + topRight[1] * 0.5];
      const tr = [topRight[0], topRight[1]];
      const bl = [bottomLeft[0], bottomLeft[1]];
      const bm = [bottomLeft[0] * 0.5 + bottomRight[0] * 0.5, bottomLeft[1] * 0.5 + bottomRight[1] * 0.5];
      const br = [bottomRight[0], bottomRight[1]];
      const lm = [topLeft[0] * 0.5 + bottomLeft[0] * 0.5, topRight[1] * 0.5 + bottomRight[1] * 0.5];
      const rm = [topRight[0] * 0.5 + bottomRight[0] * 0.5, topRight[1] * 0.5 + bottomRight[1] * 0.5];
      return this.adjustCoordsToWall([
        tl,
        tm,
        tr,
        bl,
        bm,
        br,
        lm,
        rm,
      ]);
    },
    // graph: Record<string, Set<string>>, source: string, visited: Set<string>
    depthFirstSearch(graph, source, visited) {
      visited.add(source);
      for (const neighbour of graph[source]) {
        if (!visited.has(neighbour)) {
          this.depthFirstSearch(graph, neighbour, visited);
        }
      }
    },
    highlightDisconnectedItems() {
      this.disconnectedItems.clear();
      if (this.itemElements.length < 2) return;

      const THRESHOLD = this.panelWidth * 0.04;
      const graph = Array.from(this.itemElements).reduce((graph, item) => ({ ...graph, [item.id]: new Set() }), {});
      const sides = Array.from(this.itemElements).flatMap((item) => this.getSides(item).map((side) => ({ id: item.id, side })));
      for (const { id: id1, side: side1 } of sides) {
        for (const { id: id2, side: side2 } of sides) {
          if (id1 !== id2 && this.getDistance(side1, side2) < THRESHOLD) {
            graph[id1].add(id2);
          }
        }
      }
      const vertices = Object.keys(graph);
      const visited = new Set();
      this.depthFirstSearch(graph, vertices[0], visited);
      let showMessage = false;
      for (const vertex of vertices) {
        const [path] = document.getElementById(vertex).getElementsByClassName('border');
        if (visited.has(vertex)) {
          this.disconnectedItems.delete(vertex);
          if (!this.itemsOutsideWall.has(vertex)) {
            path.style.stroke = '#F1F2F2';
          }
        } else {
          showMessage = true;
          this.disconnectedItems.add(vertex);
          path.style.stroke = '#FF0000';
        }
      }

      function waitForOverflowTooltip() {
        if (this.overflowTooltipOpen) {
          setTimeout(waitForOverflowTooltip.bind(this), 100);
        } else {
          this.invalidLayoutTooltipOpen = showMessage;
        }
      }
      if (this.overflowTooltipOpen && showMessage) {
        waitForOverflowTooltip.call(this);
      } else {
        this.invalidLayoutTooltipOpen = showMessage;
      }
      this.updateLinkerColours();
    },
    getLinkerMidpoint(linker) {
      const { left, right, top, bottom } = linker.getBoundingClientRect();
      return [(left + right) * 0.5, (top + bottom) * 0.5];
    },
    updateLinkerColours() {
      const linkers = Array.from(this.itemElements).map((item) => {
        const linkers = Array.from(item.getElementsByClassName('linker'));
        return { item: item.id, linkers };
      });

      for (const linker of linkers.flatMap(({ linkers }) => linkers)) {
        linker.style.backgroundColor = '#FFFEFF';
      }

      const { left: wallLeft, top: wallTop } = this.wallElement.getBoundingClientRect();
      const controllerCoords = [this.controllerPosition.x + wallLeft, this.controllerPosition.y + wallTop];
      const THRESHOLD = this.panelWidth * 0.14 * this.wallZoom;

      for (const { item: item1, linkers: linkers1 } of linkers) {
        for (const { item: item2, linkers: linkers2 } of linkers) {
          if (item1 !== item2) {
            for (const l1 of linkers1) {
              for (const l2 of linkers2) {
                const m1 = this.getLinkerMidpoint(l1);
                const m2 = this.getLinkerMidpoint(l2);
                if (this.getDistance(m1, m2) < THRESHOLD) {
                  l1.style.backgroundColor = '#3EAF29';
                  l2.style.backgroundColor = '#3EAF29';
                } else if (this.getDistance(m1, controllerCoords) < THRESHOLD) {
                  l1.style.backgroundColor = '#3EAF29';
                }
              }
            }
          }
        }
      }
    },
    async onDragStop(type, index, x, y, lastItem) {
      const self = document.getElementById(`${type}_${index}`);
      // toggle visibility to prevent "flashing"
      self.style.visibility = 'hidden';

      if (typeof x === 'number' && typeof y === 'number') {
        this.setPosition({ type, index, x, y, rotation: this.positions[type][index].rotation });
        await this.$nextTick();
      }

      let snapped = false;
      if (type === 'panel') {
        for (const side of this.sidesSortedByDistance(self)) {
          if (side.item.id !== 'controller_0' && await this.snapPanels(self, side.item, side.index)) {
            snapped = true;
            break;
          }
        }
      } else if (type === 'canvas') {
        for (const side of this.sidesSortedByDistance(self)) {
          if (side.item.id !== 'controller_0' && await this.snapCanvases(self, side.item, side.index)) {
            snapped = true;
            break;
          }
        }
      } else {
        for (const side of this.sidesSortedByDistance(self)) {
          const { type: sideType } = this.getTypeAndIndexFromItem(side.item);
          if (type === 'hexagon') {
            if (sideType === 'hexagon' && await this.snapHexagons(self, side.item, side.index) && await this.verifyHexagonTriangle(self)) {
              snapped = true;
              break;
            } else if (sideType === 'triangle' && await this.snapHexagonToTriangle(self, side.item, side.index) && await this.verifyHexagonTriangle(self)) {
              snapped = true;
              break;
            } else if (sideType === 'miniTriangle' && await this.snapHexagonToMiniTriangle(self, side.item, side.index) && await this.verifyHexagonTriangle(self)) {
              snapped = true;
              break;
            }
          } else if (type === 'triangle') {
            if (sideType === 'hexagon' && await this.snapTriangleToHexagon(self, side.item, side.index) && await this.verifyHexagonTriangle(self)) {
              snapped = true;
              break;
            } else if (sideType === 'triangle' && await this.snapTriangles(self, side.item, side.index) && await this.verifyHexagonTriangle(self)) {
              snapped = true;
              break;
            } else if (sideType === 'miniTriangle' && await this.snapTriangleToMiniTriangle(self, side.item, side.index) && await this.verifyHexagonTriangle(self)) {
              snapped = true;
              break;
            }
          } else if (type === 'miniTriangle') {
            if (sideType === 'hexagon' && await this.snapMiniTriangleToHexagon(self, side.item, side.index)) {
              snapped = true;
              break;
            } else if (sideType === 'triangle' && await this.snapMiniTriangleToTriangle(self, side.item, side.index)) {
              snapped = true;
              break;
            } else if (sideType === 'miniTriangle' && await this.snapPanels(self, side.item, side.index)) {
              snapped = true;
              break;
            }
          }
        }
      }

      if (lastItem) {
        if (snapped) {
          this.rotateItems();
          if (type === this.controllerLinkedTo.type && index === this.controllerLinkedTo.index) {
            await this.snapController();
          } else {
            await this.snapController(null, null, this.controllerLinkedTo);
          }
          await this.centreItems();
        }

        for (const item of this.itemElements) {
          item.style.visibility = 'visible';
        }

        this.highlightItemsOutsideWall();
        this.highlightDisconnectedItems();
        this.updateDesignSize();
        if (!this.loadingFromDesignCode) {
          this.pushStateToUndoStack();
        }
        this.generateLayoutString();
      }
      return snapped;
    },
    async snapController(x, y, linker) {
      if (this.totalItemCount === 0) return;
      if (typeof x === 'number' && typeof y === 'number') {
        this.setControllerPosition({ x, y, rotation: this.controllerPosition.rotation });
        await this.$nextTick();
      }

      const allSides = Array.from(this.itemElements).flatMap(this.getSidesWithContext);
      if (linker) {
        for (const side of allSides) {
          const { type, index } = this.getTypeAndIndexFromItem(side.item);
          if (type === linker.type && index === linker.index && side.side === linker.side) {
            const { coords } = side;
            return this.setControllerPosition({ x: coords[0], y: coords[1], rotation: this.controllerPosition.rotation });
          }
        }
      }

      // remove sides that are close together to prevent power supply from snapping between panels
      const THRESHOLD = this.panelWidth * 0.04;
      const sidesToRemove = new Set();
      for (const s1 of allSides) {
        for (const s2 of allSides) {
          if (s1 !== s2 && this.getDistance(s1.coords, s2.coords) < THRESHOLD) {
            sidesToRemove.add(s1);
            sidesToRemove.add(s2);
          }
        }
      }

      const coords = [this.controllerPosition.x, this.controllerPosition.y];
      let sideToSnap = null;
      let minDistance = Number.POSITIVE_INFINITY;
      for (const side of allSides.filter((side) => !sidesToRemove.has(side))) {
        const d = this.getDistance(coords, side.coords);
        if (d < minDistance) {
          sideToSnap = side;
          minDistance = d;
        }
      }

      const [snapX, snapY] = sideToSnap.coords;
      const { type, index } = this.getTypeAndIndexFromItem(sideToSnap.item);
      this.controllerLinkedTo = { type, index, side: sideToSnap.side };

      if (type === 'panel') {
        if (this.isRotated(sideToSnap.item)) {
          switch (sideToSnap.side) {
            case 'pivot': this.setControllerPosition({ x: snapX, y: snapY, rotation: 180 }); break;
            case 'left': this.setControllerPosition({ x: snapX, y: snapY, rotation: 60 }); break;
            case 'right': this.setControllerPosition({ x: snapX, y: snapY, rotation: 300 }); break;
          }
        } else {
          switch (sideToSnap.side) {
            case 'pivot': this.setControllerPosition({ x: snapX, y: snapY, rotation: 0 }); break;
            case 'left': this.setControllerPosition({ x: snapX, y: snapY, rotation: 120 }); break;
            case 'right': this.setControllerPosition({ x: snapX, y: snapY, rotation: 240 }); break;
          }
        }
      } else if (type === 'hexagon') {
        switch (sideToSnap.side) {
          case 'bottom': this.setControllerPosition({ x: snapX, y: snapY, rotation: 0 }); break;
          case 'leftBottom': this.setControllerPosition({ x: snapX, y: snapY, rotation: 60 }); break;
          case 'leftTop': this.setControllerPosition({ x: snapX, y: snapY, rotation: 120 }); break;
          case 'top': this.setControllerPosition({ x: snapX, y: snapY, rotation: 180 }); break;
          case 'rightTop': this.setControllerPosition({ x: snapX, y: snapY, rotation: 240 }); break;
          case 'rightBottom': this.setControllerPosition({ x: snapX, y: snapY, rotation: 300 }); break;
        }
      } else if (type == 'triangle') {
        if (this.isRotated(sideToSnap.item)) {
          switch (sideToSnap.side) {
            case 'pivotLeft': this.setControllerPosition({ x: snapX, y: snapY, rotation: 180 }); break;
            case 'pivotRight': this.setControllerPosition({ x: snapX, y: snapY, rotation: 180 }); break;
            case 'left1': this.setControllerPosition({ x: snapX, y: snapY, rotation: 60 }); break;
            case 'leftPivot': this.setControllerPosition({ x: snapX, y: snapY, rotation: 60 }); break;
            case 'right1': this.setControllerPosition({ x: snapX, y: snapY, rotation: 300 }); break;
            case 'rightPivot': this.setControllerPosition({ x: snapX, y: snapY, rotation: 300 }); break;
          }
        } else {
          switch (sideToSnap.side) {
            case 'pivotLeft': this.setControllerPosition({ x: snapX, y: snapY, rotation: 0 }); break;
            case 'pivotRight': this.setControllerPosition({ x: snapX, y: snapY, rotation: 0 }); break;
            case 'left1': this.setControllerPosition({ x: snapX, y: snapY, rotation: 120 }); break;
            case 'leftPivot': this.setControllerPosition({ x: snapX, y: snapY, rotation: 120 }); break;
            case 'right1': this.setControllerPosition({ x: snapX, y: snapY, rotation: 240 }); break;
            case 'rightPivot': this.setControllerPosition({ x: snapX, y: snapY, rotation: 240 }); break;
          }
        }
      } else if (type === 'miniTriangle') {
        if (this.isRotated(sideToSnap.item)) {
          switch (sideToSnap.side) {
            case 'pivot': this.setControllerPosition({ x: snapX, y: snapY, rotation: 180 }); break;
            case 'left': this.setControllerPosition({ x: snapX, y: snapY, rotation: 60 }); break;
            case 'right': this.setControllerPosition({ x: snapX, y: snapY, rotation: 300 }); break;
          }
        } else {
          switch (sideToSnap.side) {
            case 'pivot': this.setControllerPosition({ x: snapX, y: snapY, rotation: 0 }); break;
            case 'left': this.setControllerPosition({ x: snapX, y: snapY, rotation: 120 }); break;
            case 'right': this.setControllerPosition({ x: snapX, y: snapY, rotation: 240 }); break;
          }
        }
      }
      this.updateLinkerColours();
      if (this.activeModel === 'NL22') {
        this.setPowerSupplyPosition({ index: 0, x: snapX, y: snapY });
        this.snapPowerSupply(0, true);
      }
      if (!this.loadingFromDesignCode) {
        this.pushStateToUndoStack();
      }
    },
    snapPowerSupply(index, setByController) {
      const allSides = Array.from(this.itemElements).flatMap(this.getSides);
      const otherPowerSupplyPositions = this.powerSupplies.filter((_, i) => i !== index).map(({ x, y }) => [x, y]);
      const controllerPosition = this.activeModel === 'NL42' ? [[this.controllerPosition.x, this.controllerPosition.y]] : [];
      const allSidesIncludingPowerSupplies = allSides.concat(otherPowerSupplyPositions).concat(controllerPosition);
      // remove sides that are close together to prevent power supply from snapping between panels
      const THRESHOLD = this.panelWidth * 0.04;
      const sidesToRemove = new Set();
      for (const s1 of allSidesIncludingPowerSupplies) {
        for (const s2 of allSidesIncludingPowerSupplies) {
          if (s1 !== s2 && this.getDistance(s1, s2) < THRESHOLD) {
            sidesToRemove.add(s1);
            sidesToRemove.add(s2);
          }
        }
      }
      const coords = [this.powerSupplies[index].x, this.powerSupplies[index].y];
      const [x, y] = allSides.reduce((nearest, side) =>
        !sidesToRemove.has(side) && this.getDistance(coords, side) < this.getDistance(coords, nearest) ? side : nearest,
        [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY]);
      this.setPowerSupplyPosition({ index, x, y });
      this.$root.$emit('update-power-border', { index, x, y });

      if (this.activeModel === 'NL22' && !setByController) {
        this.snapController(x, y);
      }
    },
    isClose(array, index, threshold) {
      for (const x of array) {
        if (x && x !== array[index] && this.getDistance(x, array[index]) < threshold) {
          return true;
        }
      }
      return false;
    },
    setLightPanelsMountingPlatePositions() {
      const allSides = Array.from(this.itemElements).flatMap((item) => {
        const [left, right, pivot] = this.getAdjustedEdges(item);
        return [
          { item: item.id, coord: pivot, tag: 'pivot' },
          { item: item.id, coord: left, tag: 'left' },
          { item: item.id, coord: right, tag: 'right' },
        ];
      });

      // add all pivots first since they are in the middle so it looks better
      const positions = allSides.sort((a) => a.tag === 'pivot' ? -1 : 1).map((side) => side.coord);
      for (let i = positions.length - 1; i >= 0; i--) {
        if (this.isClose(positions, i, this.panelWidth * 0.05 * this.wallZoom)) {
          positions.splice(i, 1);
        }
      }

      this.lightPanelsMountingPlatePositions = positions;
    },
    setMountingPlateStyles() {
      for (const item of this.itemElements) {
        const { type, index } = this.getTypeAndIndexFromItem(item);
        let marginTop = '0';
        if (['triangle', 'miniTriangle'].includes(type)) {
          if (this.isRotated(item)) {
            marginTop = '-15px';
          } else {
            marginTop = '15px';
          }
        }
        const width = type === 'miniTriangle' ? '15px' : '30px';
        this.wallElement.querySelector(`#${type}_mounting_${index} img`).style.cssText = `margin-top: ${marginTop}; transform: rotate(${-this.layoutRotation}deg); width: ${width};`;
      }
    },
    generateLayoutString() {
      // Side lengths are defined by the devices
      const widths = {
        panel: 150,
        canvas: 100,
        hexagon: 134,
        triangle: 134,
        miniTriangle: 67,
      };
      const heights = {
        panel: 150 * Math.sqrt(3) / 2,
        canvas: 100,
        hexagon: 67 * Math.sqrt(3),
        triangle: 134 * Math.sqrt(3) / 2,
        miniTriangle: 67 * Math.sqrt(3) / 2,
      };
      const xScales = {
        panel: widths.panel / this.panelWidth,
        canvas: widths.canvas / this.canvasWidth,
        hexagon: widths.hexagon / this.hexagonWidth,
        triangle: widths.triangle / this.triangleWidth,
        miniTriangle: widths.miniTriangle / this.miniTriangleWidth,
      };
      const yScales = {
        panel: heights.panel / this.panelHeight,
        canvas: heights.canvas / this.canvasHeight,
        hexagon: heights.hexagon / this.hexagonHeight,
        triangle: heights.triangle / this.triangleHeight,
        miniTriangle: heights.miniTriangle / this.miniTriangleHeight,
      };
      const shapeTypes = {
        panel: 0,
        canvas: 2,
        hexagon: 7,
        triangle: 8,
        miniTriangle: 9,
      };

      const layoutStringData = Array.from(Array.from(this.itemElements).entries()).map(([i, item]) => {
        const { type, index } = this.getTypeAndIndexFromItem(item);
        const [[x, y]] = this.adjustCoordsToWall([this.getCentroid(type, this.dummyElements[i], this.isRotated(item))]);

        let { rotation: o } = this.positions[type][index];
        while (o < 0) o += 360;

        return {
          x: Math.round(x * xScales[type]),
          y: Math.round(y * yScales[type]),
          o: Math.round(o) % 360,
          t: shapeTypes[type],
        };
      });

      // reflect y coordinates
      for (const panel of layoutStringData) {
        panel.y = this.wallElement.clientHeight - panel.y;
      }
      const minY = layoutStringData.reduce((minY, { y }) => Math.min(minY, y), Number.POSITIVE_INFINITY);
      if (minY < 0) {
        for (const panel of layoutStringData) {
          panel.y -= minY;
        }
      }

      this.setLayoutStringData(layoutStringData);
    },
    getCentroid(type, element, isTriangleRotated) {
      const { top, left, height, width } = element.getBoundingClientRect();
      switch (type) {
        case 'panel':
        case 'triangle':
        case 'miniTriangle': {
          const vertices = isTriangleRotated
            ? [
              [left, top],
              [left + width, top],
              [left + width * 0.5, top + height],
            ]
            : [
              [left, top + height],
              [left + width, top + height],
              [left + width * 0.5, top],
            ];
          const avgX = (vertices[0][0] + vertices[1][0] + vertices[2][0]) / 3;
          const avgY = (vertices[0][1] + vertices[1][1] + vertices[2][1]) / 3;
          return [avgX, avgY];
        }
        case 'canvas':
        case 'hexagon':
          return [left + width * 0.5, top + height * 0.5];
      }
    },
  },
  components: {
    VueDragResizeRotate,
    LinkerGuide,
    PowerSupply,
    Toggle,
    WallTooltip,
  },
};
</script>

<style scoped>
.loading-background {
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.loading-icon {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  z-index: 4;
}

.wall-container {
  padding: 30px;
}

.wall-wrapper {
  margin-top: 30px;
  max-height: calc(100vh - 250px);
  max-width: calc(100vw - 230px);
  overflow: auto;
}

.wall {
  background-color: white;
  border-radius: 10px;
  margin: 0 auto;
  position: relative;
}

.overflow-message {
  color: red;
}

.drr:before, .drr:hover:before {
  /* Remove draggable outline from vue-drag-resize-rotate */
  outline: none !important;
}

.controller {
  z-index: 2;
}

.linker-guide {
  background-color: white;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 20px;
  text-transform: uppercase;
}

.linker-guide img {
  margin-left: 10px;
  width: 15px;
}

.linker {
  background-color: #FFFEFF;
  height: 10px;
  width: 3px;
  position: absolute;
  transform-origin: top center;
  z-index: 2;
}

.linker-canvas-rotate {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.handle {
  height: 1px;
  width: 1px;
  position: absolute;
}

.handle-tl {
  top: 0;
  left: 0;
}

.handle-tr {
  top: 0;
  right: 0;
}

.handle-bl {
  bottom: 0;
  left: 0;
}

.handle-br {
  bottom: 0;
  right: 0;
}

.mounting-header {
  align-items: center;
  justify-content: space-between;
  color: #3A4C44;
  display: block;
  font-size: 12px;
  margin: 0 auto;
  text-align: center;
}

.mounting-header > div:first-child {
  margin: 0 auto;
  width: 65%;
}

.mounting-header > div:last-child {
  margin: 0 auto;
  padding-right: 0;
  text-align: center;
  width: 55%;
}

.panel_plate {
  z-index: 2;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .mounting-header > div:first-child {
    margin: 0 auto;
  }

  .mounting-header > div:last-child {
    margin: 0 auto;
    padding-right: 0;
    text-align: center;
    width: 70%;
  }

  .mounting-header > div:last-child .wrapper {
    font-size: 12px;
    margin: 1em auto;
    width: 65%;
  }
}

@media only screen and (min-device-width: 1367px) {
  .mounting-header > div:last-child .wrapper {
    font-size: 12px;
    margin: 1em auto;
    width: 80%;
  }

  .mounting-header > div:last-child {
    width: 40%;
  }
}
</style>
