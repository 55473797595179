import * as base64 from 'byte-base64';
import { pack } from 'byte-data';

function bigEndian(n, bytes) {
  return pack(Math.round(n), { be: true, bits: bytes * 8 }).map((byte) => byte.toString(16).padStart(2, '0'));
}

function generateLayoutString(panels) {
  // See section 12 of Canvas (Pixel) Software Design Document on Google Docs for layout string format
  // type: numPanels (2 bytes)
  const bytes = ['01', '00', '02'];
  bytes.push(...bigEndian(panels.length, 2));

  // type: sideLength (2 bytes, set to 0 since it is deprecated)
  bytes.push('02', '00', '02', '00', '00');

  const panelArray = [];
  panels.forEach((panel, i) => {
    panelArray.push(...bigEndian(i, 2));
    panelArray.push(...bigEndian(panel.x, 4));
    panelArray.push(...bigEndian(panel.y, 4));
    panelArray.push(...bigEndian(panel.o, 2));
    panelArray.push(...bigEndian(panel.t, 1));
  });

  // type: panelArray
  bytes.push('03', ...bigEndian(panelArray.length, 2));
  bytes.push(...panelArray);

  return base64.bytesToBase64(bytes.map((byte) => parseInt(byte, 16)));
}

function getDesignCode(state, isUpdate) {
  state.layoutString = generateLayoutString(state.layoutStringData);
  delete state.isFromLocalStorage;

  const url = isUpdate
    ? `${process.env.VUE_APP_SAVE_URL}/${state.designCode}`
    : process.env.VUE_APP_SAVE_URL;
  const method = isUpdate ? 'PUT' : 'POST';

  return new Promise((resolve, reject) => {
    fetch(url, {
      body: JSON.stringify(state),
      headers: { 'Content-Type': 'application/json' },
      method,
    }).then((res) => {
      if (isUpdate && res.status === 204) {
        resolve(state.designCode);
      } else if (!isUpdate && res.status === 201) {
        res.json().then(({ key }) => resolve(key));
      } else {
        reject({ status: res.status });
      }
    }).catch(() => reject({ status: 500 }));
  });
}

export default getDesignCode;
