<template>
  <div>
    <div v-if="!b2b" class="smarter-or-expansion">
      <div v-show="smarterKitDisabled" class="disabled-overlay"></div>
      <div class="text">
        <div class="title">Step 1: required</div>
        <div class="subtitle">Add Smarter Kit</div>
        <div class="description" v-html="smarterKitDescription"></div>
      </div>
      <button :class="smarterKitButtonClasses" @click="handleSmarterKitClick"></button>
      <div style="flex-basis: 100%; height: 0; margin-bottom: 15px;"></div>
      <BoxCount :model-name="modelName" :size="smarterKitSize" :count="smarterKitSelected ? 1 : 0" />
    </div>
    <div class="smarter-or-expansion">
      <div v-show="!(b2b || smarterKitSelected || selectedShapesSmarterKit)" class="disabled-overlay"></div>
      <div class="text">
        <div class="title">Step 2: optional</div>
        <div class="subtitle">Select Expansion Pack</div>
        <div class="description" v-html="expansionPackDescription"></div>
      </div>
      <button :class="expansionPackButtonClasses" @click="handleExpansionPackClick"></button>
    </div>
    <div v-show="ePackSelected" class="expansion-packs-wrapper">
      <AddExpansionPacks v-for="(pack, $index) in expansionPacks" v-show="shouldShowExpansionPack($index)" :key="$index"
        :model-name="modelName" :size="pack.size" :count="pack.count" @change="changeExpansionPackCount($index, $event)" />
    </div>
  </div>
</template>

<script>
import AddExpansionPacks from './AddExpansionPacks.vue';
import BoxCount from './BoxCount.vue';

export default {
  name: 'AddPanels',
  props: {
    b2b: {
      type: Boolean,
      default() {
        return false;
      },
    },
    modelName: {
      type: String,
      required: true,
    },
    smarterKitSize: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
    smarterKitSelected: {
      type: Boolean,
      required: true,
    },
    smarterKitDisabled: {
      type: Boolean,
      required: true,
    },
    selectedShapesSmarterKit: {
      type: String,
    },
    expansionPackSelected: {
      type: Boolean,
      required: true,
    },
    expansionPacks: {
      type: Array,
      required: true,
      validator(value) {
        const nonNegative = (prop) => typeof prop === 'number' && prop >= 0;
        return value.every((pack) => nonNegative(pack.size) && nonNegative(pack.count) && typeof pack.b2b === 'boolean');
      },
    },
    count: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
  },
  data() {
    return {
      ePackSelected: this.expansionPackSelected,
    };
  },
  watch: {
    count(newCount) {
      // Handle reset event
      if (newCount === 0) {
        this.ePackSelected = false;
      }
    },
  },
  computed: {
    smarterKitButtonClasses() {
      const model = this.modelName.toLowerCase().replace(/\s/g, '-');
      const classes = `btn btn-select ga-change-${model}-count`;
      return this.smarterKitSelected ? `${classes} selected` : classes;
    },
    smarterKitDescription() {
      return this.smarterKitDisabled
        ? `Remove ${this.selectedShapesSmarterKit} Smarter Kit to acquire ${this.modelName} Smarter Kit`
        : `Contains <strong>${this.smarterKitSize} panels</strong> and everything you need`;
    },
    expansionPackButtonClasses() {
      const model = this.modelName.toLowerCase().replace(/\s/g, '-');
      const classes = `btn btn-select ga-change-${model}-count`;
      return this.ePackSelected ? `${classes} selected` : classes;
    },
    expansionPackDescription() {
      const sizes = this.expansionPacks.filter((_, i) => this.shouldShowExpansionPack(i)).map((pack) => pack.size).join(' or ');
      return `Contains <strong>${sizes} panels</strong> if you wish to expand your creation`;
    },
  },
  methods: {
    handleSmarterKitClick() {
      return this.smarterKitSelected ? this.deselectSmarterKit() : this.selectSmarterKit();
    },
    handleExpansionPackClick() {
      return this.ePackSelected ? this.deselectExpansionPack() : this.selectExpansionPack();
    },
    selectSmarterKit() {
      if (!this.smarterKitDisabled) {
        this.$emit('change', {
          smarterKitSelected: true,
          expansionPacks: this.expansionPacks.map((pack) => ({ ...pack, count: 0 })),
          count: this.smarterKitSize,
        });
      }
    },
    selectExpansionPack() {
      const smarterKitSelected = !this.b2b && !this.smarterKitDisabled;
      const firstExpansionPackIndex = this.expansionPacks.findIndex((_, i) => this.shouldShowExpansionPack(i));
      this.$emit('change', {
        smarterKitSelected,
        expansionPacks: this.expansionPacks.map((pack, i) => i === firstExpansionPackIndex ? { ...pack, count: 1 } : pack),
        count: (smarterKitSelected ? this.smarterKitSize : 0) + this.expansionPacks[firstExpansionPackIndex].size,
      });
      this.ePackSelected = true;
    },
    deselectSmarterKit() {
      this.ePackSelected = false;
      this.$emit('change', {
        smarterKitSelected: false,
        expansionPacks: this.expansionPacks.map((pack) => ({ ...pack, count: 0 })),
        count: 0,
      });
    },
    shouldShowExpansionPack(index) {
      return !this.b2b || this.expansionPacks[index].b2b;
    },
    deselectExpansionPack() {
      this.ePackSelected = false;
      this.$emit('change', {
        smarterKitSelected: this.smarterKitSelected,
        expansionPacks: this.expansionPacks.map((pack) => ({ ...pack, count: 0 })),
        count: this.b2b || this.smarterKitDisabled ? 0 : this.smarterKitSize,
      });
    },
    changeExpansionPackCount(index, newCount) {
      const oldExpansionCount = this.expansionPacks.reduce((total, pack) => total + pack.size * pack.count, 0);
      const expansionPacks = this.expansionPacks.map((pack, i) => i === index ? ({ ...pack, count: newCount }) : pack);
      const newExpansionCount = expansionPacks.reduce((total, pack) => total + pack.size * pack.count, 0);
      this.$emit('change', {
        smarterKitSelected: this.smarterKitSelected,
        expansionPacks,
        count: this.count + newExpansionCount - oldExpansionCount,
      });
    },
  },
  components: {
    AddExpansionPacks,
    BoxCount,
  },
};
</script>

<style scoped>
.smarter-or-expansion {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 5px;
  margin: 15px 0;
  padding: 20px;
  position: relative;
}

.text {
  width: 80%;
}

.title {
  color: #3EAF29;
  font-size: 12px;
  font-weight: bold;
}

.subtitle {
  font-size: 22px;
  font-weight: bold;
  margin: 5px 0;
  padding-left: 0;
}

.description {
  font-size: 12px;
  opacity: 0.25;
}

.btn-select {
  background-size: 25px;
  height: 25px;
  width: 25px;
}

.expansion-packs-wrapper {
  background-color: white;
  margin-top: -15px;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 10px;
}

.disabled-overlay {
  background-color: #EEE;
  opacity: 0.5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .btn-total-reset img {
      margin-left: 5px;
  }
}
</style>
